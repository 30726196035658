import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../@service/auth.service';
import { Logger } from 'src/app/@utility/logger';

const logger = new Logger('AuthorizationInterceptor');

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this.authService.getSession();

    logger.debug('access token : ' + token?.accessToken);
    logger.debug('refresh token : ' + token?.refreshToken);

    if (!!token?.accessToken && !!token?.refreshToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token?.accessToken}`,
        },
      });
    }

    return next.handle(request);
  }
}
