import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import liff from '@line/liff';
import { isEmpty } from 'lodash';
import { ApiService } from 'src/app/@service/api.service';
import { AuthService } from 'src/app/@service/auth.service';
import { ToastService } from 'src/app/@service/toast.service';
import { UtilityService } from 'src/app/@service/utility.service';
import { Logger } from 'src/app/@utility/logger';

const logger = new Logger('OauthLineComponent');

@Component({
  selector: 'app-oauth-line',
  templateUrl: './oauth-line.component.html',
  styleUrls: ['./oauth-line.component.scss'],
})
export class OauthLineComponent implements OnInit {
  lineCode: string;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private utilityService: UtilityService,
    private toastService: ToastService
  ) {
    this.route.queryParams.subscribe(params => {
      this.lineCode = params['code'];
    });
  }

  ngOnInit() {
    this.lineVerify();
  }

  lineVerify() {
    logger.debug(`line verify called`);
    console.log(`line verify called`);
    liff.ready.then(async () => {
      try {
        const tokens = this.authService.getSession();
        if (!!tokens?.accessToken && !!tokens?.refreshToken) {
          logger.debug(`access token and refresh token is undefined`);
          return this.router.navigate(['/informative']);
        }

        if (!this.lineCode) {
          logger.debug(`lineCode is undefined`);
          return this.router.navigate(['/informative']);
        }

        if (!liff.isLoggedIn()) {
          logger.debug(`liff is not login`);
          throw new Error('Liff is not login');
        }

        const idToken = liff.getIDToken();
        if (!idToken) {
          logger.debug(`idToken from getIDToken is undefined`);
          throw new Error('Line Id Token is empty');
        }
        console.log(`line verify`);
        const authPayload = await this.apiService
          .lineVerify(idToken)
          .catch(reason => {
            logger.debug(`line verify error reason : ${reason}`);
            throw new Error(reason);
          });

        if (isEmpty(authPayload)) {
          logger.debug(`authPayload is empty`);
          throw new Error('uthPayload is empty');
        }

        const { accessToken, refreshToken } = authPayload.stsTokenManager;

        this.authService.setSession(accessToken, refreshToken);

        const path = this.authService.getDestinationPath();
        console.log('path ' + path);
        this.authService.setDestinationPath();
        return this.router.navigate([!!path ? path : '/informative']);
      } catch (error) {
        logger.error(error);
        this.toastService.show(
          'เกิดข้อผิดพลาด',
          this.utilityService.errorFormat(error).message
        );
        return this.router.navigate(['/informative']);
      }
    });
  }
}
