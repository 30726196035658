import axios from 'axios';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  private endpoint = environment.apiUrl;

  constructor(private authService: AuthService) {}

  async upload(file: File) {
    if (!file) return '';
    const tokens = this.authService.getSession();
    if (!tokens) return '';
    const fileName = `${Date.now()}-${file.name.split(' ').join('')}`;
    const signedUrl = await this.getSignedUrl(tokens.accessToken, fileName);
    const uploadResp = await this.uploadFile(signedUrl, file);
    if (uploadResp.status !== 200) return '-';
    return this.getFileUrl(fileName);
  }

  private async getSignedUrl(accessToken: string, fileName: string) {
    const url = `${this.endpoint}/api/signed-url/${fileName}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const signedUrl = await response.text();
    return signedUrl;
  }

  private async uploadFile(signedUrl: string, file: File) {
    const response = await axios.put(signedUrl, file, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });
    return response;
  }

  private getFileUrl(fileName: string) {
    const { url: firebaseStorageUrl, replaceKey } = environment.firebaseStorage;
    const encodePath = fileName.toString().split('/').join('%2F');
    const fileUrl = firebaseStorageUrl.replace(replaceKey, encodePath);
    return fileUrl;
  }
}
