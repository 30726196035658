<div class="footer-container">
  <p class="title-text mb-2">FOLLOW US ON</p>
  <div class="contact-container">
    <a
      class="contact-button"
      *ngFor="let cont of contacts"
      [href]="cont.url"
      target="_blank">
      <img [src]="cont.logoSrc" />
    </a>
  </div>
</div>
<div>
  <p class="text-center text-tel-footer">
    สอบถามข้อมูล : กองส่งเสริมและพัฒนาการปศุสัตว์ กรมปศุสัตว์ <br />โทร ๐ ๒๖๕๓
    ๔๔๔๔ ต่อ ๓๓๒๓-๔
  </p>
</div>
