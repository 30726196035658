<app-loading *ngIf="fetching" [isOutlet]="true"></app-loading>

<div class="content px-3 mt-4 mb-5">
  <a [routerLink]="['/enrollments']" class="btn btn-xs btn-warning">ย้อนกลับ</a>

  <div *ngIf="fetching" class="mt-2">
    <h5 class="text-muted">กำลังดึงข้อมูล</h5>
  </div>

  <section *ngIf="!fetching" class="mt-4" id="general-info">
    <h5 class="mt-1 mb-2">ข้อมูลทั่วไป</h5>
    <dl>
      <dt>สถานะการตรวจสอบ</dt>
      <dd>
        <app-approve-status
          [status]="enrollment.approveStatus"></app-approve-status>
      </dd>
      <dt>ชื่อนามสกุล</dt>
      <dd>{{ enrollment.name }}</dd>
      <dt>เบอร์ติดต่อ</dt>
      <dd>{{ enrollment.mobilePhone }}</dd>
    </dl>
  </section>
  <hr />
  <section class="mt-4" id="address-info">
    <h5 class="mt-1 mb-2">ที่อยู่</h5>
    <dl>
      <dt>บ้านเลขที่</dt>
      <dd>
        {{
          enrollment && enrollment.address
            ? enrollment.address.houseNumber
            : '-'
        }}
      </dd>
      <dt>จังหวัด</dt>
      <dd>
        {{
          enrollment && enrollment.address ? enrollment.address.province : '-'
        }}
      </dd>
      <dt>อำเภอ</dt>
      <dd>
        {{
          enrollment && enrollment.address ? enrollment.address.district : '-'
        }}
      </dd>
      <dt>ตำบล</dt>
      <dd>
        {{
          enrollment && enrollment.address
            ? enrollment.address.subDistrict
            : '-'
        }}
      </dd>
      <dt>รหัสไปรษณีย์</dt>
      <dd>
        {{
          enrollment && enrollment.address ? enrollment.address.postalCode : '-'
        }}
      </dd>
    </dl>
  </section>
  <hr />
  <section *ngIf="enrollment?.buffaloDetail" class="mt-4" id="buffalo-info">
    <h5 class="mt-1 mb-2">ข้อมูลกระบือ</h5>
    <dl>
      <dt>สมัครประเภท</dt>
      <dd>
        <div>
          {{
            enrollment.enrollmentCategory && enrollment.enrollmentCategory.name
              ? enrollment.enrollmentCategory.name
              : '-'
          }}
        </div>
        <p class="text-sm text-muted">
          {{
            enrollment.enrollmentCategory &&
            enrollment.enrollmentCategory.detail
              ? enrollment.enrollmentCategory.detail
              : '-'
          }}
        </p>
      </dd>
      <dt>เพศ</dt>
      <dd>
        {{
          enrollment.gender
            ? enrollment.gender === 'female'
              ? 'เมีย'
              : 'ผู้'
            : '-'
        }}
      </dd>
      <dt>สี</dt>
      <dd>
        {{
          enrollment.color
            ? enrollment.color === 'albino'
              ? 'เผือก'
              : 'ดำ'
            : '-'
        }}
      </dd>
      <dt>ชื่อกระบือ</dt>
      <dd>
        {{ enrollment.buffaloDetail ? enrollment.buffaloDetail.name : '-' }}
      </dd>
      <dt>เลขประจำตัวสัตว์</dt>
      <dd>
        {{ enrollment.buffaloDetail ? enrollment.buffaloDetail.code : '-' }}
      </dd>
      <dt>วันเกิดกระบือ</dt>
      <dd>
        {{
          enrollment.buffaloDetail ? enrollment.buffaloDetail.birthDate : '-'
        }}
      </dd>
      <dt>ความยาวรอบอก</dt>
      <dd>
        {{
          enrollment.buffaloDetail ? enrollment.buffaloDetail.chestLength : '-'
        }}
      </dd>
      <dt>บัตรประจำตัวสัตว์</dt>
      <dd>
        <img
          [src]="
            enrollment.buffaloDetail
              ? enrollment.buffaloDetail.imgIdentityCard
              : 'assets/png/image-not-found.png'
          "
          class="img-thumbnail" />
      </dd>
      <dt>ภาพถ่ายหน้าตรง</dt>
      <dd>
        <img
          [src]="
            enrollment.buffaloDetail
              ? enrollment.buffaloDetail.imgStraightFace
              : 'assets/png/image-not-found.png'
          "
          class="img-thumbnail" />
      </dd>
      <dt>ภาพถ่ายด้านข้าง</dt>
      <dd>
        <img
          [src]="
            enrollment.buffaloDetail
              ? enrollment.buffaloDetail.imgSideView
              : 'assets/png/image-not-found.png'
          "
          class="img-thumbnail" />
      </dd>
      <dt>ภาพถ่ายด้านท้าย</dt>
      <dd>
        <img
          [src]="
            enrollment.buffaloDetail
              ? enrollment.buffaloDetail.imgRearView
              : 'assets/png/image-not-found.png'
          "
          class="img-thumbnail" />
      </dd>
      <!-- <dt>เอกสารการตรวจโรคแท้งติดต่อ</dt>
      <dd>
        <img
          [src]="
            enrollment.buffaloDetail
              ? enrollment.buffaloDetail.imgDiseaseDoc
              : 'assets/png/image-not-found.png'
          "
          class="img-thumbnail" />
      </dd>
      <dt>ใบรับรองการฉีดวัคซีน</dt>
      <dd>
        <img
          [src]="
            enrollment.buffaloDetail
              ? enrollment.buffaloDetail.imgCertificateVaccine
              : 'assets/png/image-not-found.png'
          "
          class="img-thumbnail" />
      </dd> -->
    </dl>
  </section>
</div>
