import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../@service/auth.service';
import { isEmpty } from 'lodash';
import { Logger } from 'src/app/@utility/logger';

const logger = new Logger('JwtGuard');

@Injectable({
  providedIn: 'root',
})
export class JwtGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const tokens = this.authService.getSession();
    logger.debug(`check can activate ${tokens?.accessToken}`);
    return new Promise<boolean>(resolve => {
      (async () => {
        if (!!tokens?.accessToken && !!tokens?.refreshToken) {
          this.authService.setSession(
            tokens?.accessToken,
            tokens?.refreshToken
          );
          isEmpty(this.authService.userState) &&
            (await this.authService.setUserInfo());

          if (
            !isEmpty(this.authService.userState) &&
            !this.authService.userState?.isInitProfile &&
            state.url !== '/registration'
          ) {
            this.router.navigate(['/registration']);
          }
          return resolve(true);
        }
        this.authService.setDestinationPath(window.location.pathname);

        this.authService.logout();
        return resolve(false);
      })();
    });
  }
}
