import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotFoundError } from 'rxjs';
import { ApiService } from 'src/app/@service/api.service';
import { ToastService } from 'src/app/@service/toast.service';
import { UtilityService } from 'src/app/@service/utility.service';
import { Enrollment } from 'src/app/@type/enrollment.type';
import { Logger } from 'src/app/@utility/logger';

const logger = new Logger('EnrollmentComponent');

@Component({
  selector: 'app-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.scss'],
})
export class RegisterSuccessComponent implements OnInit {
  fetching = true;
  error = false;
  enrollmentId: string;
  enrollment: Enrollment;

  constructor(
    private route: ActivatedRoute,
    private toastService: ToastService,
    private utilityService: UtilityService,
    private apiService: ApiService,
    private router: Router
  ) {
    const enrollmentId = this.route.snapshot.paramMap.get('enrollmentId');
    !!enrollmentId && (this.enrollmentId = enrollmentId);
  }

  async ngOnInit() {
    this.fetching = true;
    await this.mount();
    this.fetching = false;
  }

  async mount() {
    if (this.enrollmentId) {
      try {
        this.error = false;
        await this.fetchEnrollment();
      } catch (error) {
        this.error = true;
        logger.error(error);
        this.toastService.show(
          'เกิดข้อผิดพลาด',
          this.utilityService.errorFormat(error).message
        );
      }
    }
  }

  async fetchEnrollment() {
    const response = (await this.apiService.getEnrollmentById(
      this.enrollmentId
    )) as any;

    // check if existed
    if (response) {
      this.enrollment = response;
    } else {
      throw new NotFoundError(`Enrollment not found`);
    }
  }

  navigate(to: string) {
    this.router.navigate([to]);
  }
}
