import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-approve-status',
  templateUrl: './approve-status.component.html',
  styleUrls: ['./approve-status.component.scss'],
})
export class ApproveStatusComponent implements OnInit {
  statusString: string;
  bg: string;
  @Input() status: number;

  ngOnInit() {
    switch (this.status) {
      case 0:
        this.statusString = 'กำลังรอการตรวจสอบ';
        this.bg = 'dark';
        break;
      case 1:
        this.statusString = 'ผ่านการตรวจสอบ';
        this.bg = 'success';
        break;
      case -1:
        this.statusString = 'ไม่ผ่านการตรวจสอบ';
        this.bg = 'danger';
        break;
      default:
        this.statusString = 'กำลังรอการตรวจสอบ';
        this.bg = 'dark';
        break;
    }
  }
}
