import { Component, OnInit } from '@angular/core';
import { ChildrenOutletContexts, NavigationEnd, Router } from '@angular/router';
import { routerTransition } from './@utility/router-transition-animation';
import liff from '@line/liff';
import { environment } from 'src/environments/environment';
import { Logger } from './@utility/logger';
import { UtilityService } from './@service/utility.service';
import { ToastService } from './@service/toast.service';
import { sidebarTransition } from './@utility/sidebar-animation';

const logger = new Logger('AppComponent');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition, sidebarTransition],
})
export class AppComponent implements OnInit {
  title = 'steeker';
  initializing = true;
  sidebarState: 'in' | 'out' = 'out';

  constructor(
    private contexts: ChildrenOutletContexts,
    private utilityService: UtilityService,
    private toastService: ToastService,
    private router: Router
  ) {}

  async ngOnInit() {
    try {
      console.log('start init line liff');
      // NOTE : comment this if you want to skip
      await this.initLiff();
      console.log('initiated line liff');
      await this.utilityService.sleep(500);
      console.log('wakeup');
    } catch (error) {
      logger.error(error);
      console.log(error);
      this.toastService.show(
        'เกิดข้อผิดพลาด',
        this.utilityService.errorFormat(error).message
      );
    } finally {
      this.initializing = false;
    }

    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    setTimeout(() => {
      logger.debug(`hacking initializing = false`);
      this.initializing = false;
      console.log(`hacking initializing`);
    }, 5000);
  }

  async initLiff() {
    await liff.init({
      liffId: environment.liffId,
      withLoginOnExternalBrowser: true,
    });
  }

  // transition animation
  getRouteAnimation() {
    const data = this.contexts.getContext('primary')?.route?.snapshot?.data;
    return data?.['animateState'];
  }

  toggleSidebar(state: 'in' | 'out') {
    this.sidebarState = state;
  }
}
