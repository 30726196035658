<div class="content px-3 mt-4 mb-5">
  <div class="text-center">
    <h5>หลักเกณฑ์ ข้อกำหนด และเงื่อนไข</h5>
    <h6>
      สำหรับกระบือที่ผ่านการเข้าประกวดกระบือปลักไทย
      ชิงถ้วยพระราชทานพระบาทสมเด็จพระเจ้าอยู่หัว
    </h6>
    <h6>13-14 มกราคม 2567</h6>
  </div>
  <div class="mt-4">
    <p>
      1. ต้องติดต่อปศุสัตว์ในพื้นที่เพื่อดำเนินการขอเอกสารดังนี้ 1.2
      บัตรประจำตัวสัตว์ (บัตรเขียว)
    </p>
    <p>
      2. ขอตรวจโรคแท้งติดต่อ และรับวัคซีน เพื่อรับรองในเอกสารด้านสุขภาพกระบือ
      นำมาแสดงในวันตรวจสอบหลักฐาน ที่จุดลงทะเบียนเข้าประกวด <br />
      &nbsp;&nbsp;&nbsp;&nbsp;2.1 หนังสือรับรองการตรวจโรคแท้งติดต่อ
      (Brucellosis) จากหน่วยงานของกรมปศุสัตว์ ไม่มีค่าใช้จ่าย<br />
      &nbsp;&nbsp;&nbsp;&nbsp;2.2 ใบรับรองการฉีดวัคซีน จากหน่วยงานของกรมปศุสัตว์
      ประกอบด้วย
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.2.1) โรคปากและเท้าเปื่อย
      FMD (ต้องได้รับวัคซีนเข็มที่2 มาแล้วอย่างน้อย 21 วัน และไม่เกิน 120 วัน
      ไม่มีค่าใช้จ่าย) <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.2.2) โรคคอบวม
      (ได้รับวัคซีน 1 เข็ม อย่างน้อย 21 วัน และไม่เกิน 1 ปี ไม่มีค่าใช้จ่าย)
    </p>
    <p>
      3. ในวันลงทะเบียนจะมีเจ้าหน้าที่เจาะเลือด
      เพื่อตรวจโครโมโซมยืนยันการเป็นกระบือปลัก
    </p>
    <p>
      4. โปรดนำกระบือเข้าลงทะเบียนและตรวจสอบหลักฐาน ตามสีของกระบือ ดังนี้ <br />
      &nbsp;&nbsp;&nbsp;&nbsp;• วันที่ 12 มกราคม 2567 เริ่มตั้งแต่เวลา
      13.00-20.00 น. (กระบือเผือก)
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;• วันที่ 13 มกราคม 2567 เริ่มตั้งแต่เวลา
      13.00-20.00 น. (กระบือดำ)
    </p>
    <p>
      5. ข้อกำหนดในการประกวดกระบือ<br />
      &nbsp;&nbsp;&nbsp;&nbsp;5.1 ห้ามยัดหรือป้อนหญ้า ห้ามกรอกอาหาร กรอกน้ำ
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;5.2 กระบือที่เข้าประกวดทุกตัว
      ต้องมีการตรวจหาสารเร่งเนื้อแดงจากปัสสาวะ
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;5.3 กระบือทุกตัวเมื่อมารายงานตัว
      ตรวจสอบเอกสารหลักฐานเรียบร้อยแล้ว ให้อยู่ในคอกพักที่จัดไว้
      ห้ามนำกระบือออกนอกบริเวณคอกพักที่กำหนด <br />
      &nbsp;&nbsp;&nbsp;&nbsp;5.4 ห้ามกระทำการใดๆ ที่ผิดกฎหมายทุกประการ
      หากพบจะตัดสิทธิ์การร่วมประกวด หรือตัดสิทธิ์ในการได้รางวัล
    </p>
    <p>
      6. เงื่อนไขกระบือที่ได้รับรางวัล<br />
      &nbsp;&nbsp;&nbsp;&nbsp;6.1 มีการมอบถ้วยรางวัลสำหรับกระบือที่ได้รับ
      รางวัลชนะเลิศ ลำดับที่ 1 – 3 ทุกรุ่น
      และจะนำข้อมูลเพื่อจัดทำเป็นหนังสือรวมพ่อพันธุ์และแม่พันธุ์กระบือปลักไทย
      และเผยแพร่ให้เกษตรกรนำไปใช้เป็นต้นแบบในการปรับปรุงพันธุ์ต่อไป
      รางวัลลำดับที่ 4-10 มอบเป็นใบประกาศนียบัตร <br />
      &nbsp;&nbsp;&nbsp;&nbsp;6.2 กระบือเพศผู้ที่ได้รับรางวัลที่ 1-3
      ในรุ่นฟันแท้ 1 คู่ และฟันแท้ 2 คู่ทุกตัว
      เมื่อผ่านการตรวจโรคที่สำคัญทางระบบสืบพันธุ์แล้ว
      เจ้าของกระบือต้องยินยอมให้นำเข้ารีตน้ำเชื้อ
      โดยศูนย์น้ำเชื้อที่ได้รับรองมาตรฐานจากกรมปศุสัตว์
      เพื่อเก็บรักษาพันธุกรรมกระบือปลักไทย <br />
      &nbsp;&nbsp;&nbsp;&nbsp;6.3 กระบือที่ได้รับรางวัล Grand Champion
      เมื่อได้รับการประกาศผลในงานแล้ว
      จะเข้ารับพระราชทานถ้วยรางวัลต่อหน้าพระบรมฉายาลักษณ์ ที่กรมปศุสัตว์
      ภายหลังทราบผลการตรวจโรคติดต่อทางพันธุกรรม หากตรวจพบว่าเป็นโรคตามที่กำหนด
      จะพิจารณารางวัลให้กระบือตัวที่ได้รับรางวัลถัดไปแทน
    </p>
  </div>
</div>
