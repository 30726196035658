<div class="wrapper-informative-page">
  <div class="landing-container px-3 pt-1 pb-4">
    <div class="text-center">
      <img alt="trophy" src="./../../../assets/svg/page/trophy.svg" />
    </div>
    <div class="mt-2 mb-3">
      <h3 class="landing-top-title">ประกวดกระบือปลักไทย</h3>
      <h2 class="landing-mid-title">GRAND CHAMPION</h2>
      <h4 class="landing-bottom-title">
        ชิงถ้วยพระราชทานพระบาทสมเด็จพระเจ้าอยู่หัว
      </h4>
    </div>
    <p class="landing-sub-title mb-4">
      เรียนชิญร่วมงานวันอนุรักษ์และพัฒนากระบือปลักไทย ประจำปี ๒๕๖๗ ระหว่างวันที่
      ๑๓ - ๑๔ มกราคม ๒๕๖๗ ณ ศูนย์วิจัยและพัฒนาโคนม อำเภอปากช่อง
      จังหวัดนครราชสีมา
    </p>
    <div
      class="d-flex flex-column align-items-center justify-content-center gap-2">
      <button
        (click)="navigate('/register')"
        *ngIf="isOpenToRegister"
        class="btn btn-register d-flex gap-2 align-items-center justify-content-center"
        type="button">
        <span>ลงทะเบียนประกวด</span>
        <img alt="register" src="./../../../assets/svg/page/regis-icon.svg" />
      </button>
      <button
        [routerLink]="'/criterion'"
        class="btn btn-register"
        type="button">
        เกณฑ์การประกวด
      </button>

      <!-- <button class="btn btn-register" type="button">
        เริ่มรับสมัคร ๑๕ พ.ย. ๒๕๖๖
      </button> -->

      <button
        (click)="scrollCriteria(criteria)"
        class="btn btn-scroll-down"
        type="button">
        <svg
          class="bi bi-chevron-down"
          fill="currentColor"
          height="36"
          viewBox="0 0 16 16"
          width="36"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
            fill-rule="evenodd" />
        </svg>
      </button>
    </div>
  </div>
  <div #criteria></div>

  <div *ngIf="loading" class="my-5">
    <app-loader></app-loader>
  </div>
  <div *ngIf="enrollmentCategories.length > 0" class="criteria-container p-3">
    <p class="criteria-title mb-3">รุ่นกระบือปลักไทย ดำ/เผือก เพศผู้/เพศเมีย</p>

    <app-criteria-card
      (click)="navigate('/register', enrollmentCategory.id)"
      *ngFor="let enrollmentCategory of enrollmentCategories"
      [description]="
        enrollmentCategory.description ? enrollmentCategory.description : ''
      "
      [detail]="enrollmentCategory.detail ? enrollmentCategory.detail : ''"
      [stat]="enrollmentCategory.stat ? enrollmentCategory.stat : 0"
      [title]="enrollmentCategory.name">
    </app-criteria-card>
  </div>
</div>
