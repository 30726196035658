export type ObjectValueType<T> = T[keyof T];

export type KeyedObject = {
  [key: string]: string | number | boolean | KeyedObject;
};

export const LocalStorageKey = {
  AccessToken: 'steeker__access_token',
  RefreshToken: 'steeker__refreshtoken_token',
  DestinationPath: 'steeker__destination_path',
} as const;
export type LocalStorageKey = ObjectValueType<typeof LocalStorageKey>;

export type PageInfo = {
  limit: number;
  page: number;
  totalPages: number;
  totalResults: number;
};

export type Time = {
  _seconds: number;
  _nanoseconds: number;
};
