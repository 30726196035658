<div class="form-container">
  <form [formGroup]="profileForm" (ngSubmit)="handleSubmit()">
    <div class="form-group">
      <label>ชื่อจริง</label>
      <input
        formControlName="firstname"
        placeholder="กรอกชื่อจริงของคุณ"
        type="text"
        class="form-control" />
      <small
        *ngIf="
          formService.isControlInvalid(profileForm, 'firstname') &&
          firstnameControl?.errors?.['required']
        "
        class="text-danger d-block w-100 px-3 pb-3 validate-text"
        >จำเป็นต้องระบุ</small
      >
    </div>

    <div class="form-group">
      <label>นามสกุล</label>
      <input
        formControlName="lastname"
        placeholder="กรอกนามสกุลของคุณ"
        type="text"
        class="form-control" />
      <small
        *ngIf="
          formService.isControlInvalid(profileForm, 'lastname') &&
          lastnameControl?.errors?.['required']
        "
        class="text-danger d-block w-100 px-3 pb-3 validate-text"
        >จำเป็นต้องระบุ</small
      >
    </div>

    <div class="form-group">
      <label>ชื่อเล่น</label>
      <input
        formControlName="nickname"
        placeholder="กรอกชื่อเล่นของคุณ"
        type="text"
        class="form-control" />
      <small
        *ngIf="
          formService.isControlInvalid(profileForm, 'nickname') &&
          nicknameControl?.errors?.['required']
        "
        class="text-danger d-block w-100 px-3 pb-3 validate-text"
        >จำเป็นต้องระบุ</small
      >
    </div>

    <div class="form-group">
      <label>เบอร์โทรศัพท์</label>
      <input
        formControlName="mobilePhone"
        placeholder="กรอกเบอร์โทรศัพท์ของคุณ"
        type="text"
        [inputMask]="mobilePhoneMask"
        class="form-control" />

      <ng-container
        *ngIf="formService.isControlInvalid(profileForm, 'mobilePhone')">
        <small
          *ngIf="mobilePhoneControl?.errors?.['inputMask']"
          class="text-danger d-block w-100 px-3 pb-3 validate-text"
          >รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง</small
        >
        <small
          *ngIf="mobilePhoneControl?.errors?.['required']"
          class="text-danger d-block w-100 px-3 pb-3 validate-text"
          >จำเป็นต้องระบุ</small
        >
      </ng-container>
    </div>

    <div class="mt-4 z-500">
      <button
        [disabled]="submitting"
        type="submit"
        class="btn btn-warning btn-lg btn-block btn-main w-100">
        {{ submitText }}
      </button>
    </div>
  </form>
</div>
