import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './@page/page-not-found/page-not-found.component';
import { RegistrationComponent } from './@page/registration/registration.component';
import { LoadingComponent } from './@component/loading/loading.component';
import { BackButtonComponent } from './@component/back-button/back-button.component';
import { GetRewardsModalComponent } from './@component/get-rewards-modal/get-rewards-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './@page/profile/profile.component';
import { ProfileFormComponent } from './@component/profile-form/profile-form.component';
import { InputMaskModule } from '@ngneat/input-mask';
import { LoginComponent } from './@page/login/login.component';
import { ToastComponent } from './@component/toast/toast.component';
import { OauthLineComponent } from './@page/oauth-line/oauth-line.component';
import { LoaderComponent } from './@component/loader/loader.component';
import { ImageUriPipe } from './@pipe/image-uri.pipe';
import { ErrorComponent } from './@component/error/error.component';
import { AuthorizationInterceptor } from './@interceptor/authorization.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorInterceptor } from './@interceptor/error.interceptor';
import { QRCodeModule } from 'angularx-qrcode';
import { InformativeComponent } from './@page/informative/informative.component';
import { CriteriaCardComponent } from './@component/criteria-card/criteria-card.component';
import { FooterComponent } from './@component/footer/footer.component';
import { SidebarComponent } from './@component/sidebar/sidebar.component';
import { EnrollmentsComponent } from './@page/enrollments/enrollments.component';
import { EnrollmentComponent } from './@page/enrollment/enrollment.component';
import { RegisterComponent } from './@page/register/register.component';
import { ApproveStatusComponent } from './@component/approve-status/approve-status.component';
import { CriterionComponent } from './@page/criterion/criterion.component';
import { OnlyNumberDirective } from './@directive/only-number.directive';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { RegisterSuccessComponent } from './@page/register-success/register-success.component';
import { AbsoluteLoadingComponent } from './@component/absolute-loading/absolute-loading.component';
import { ApprovedCriterionComponent } from './@page/approved-criterion/approved-criterion.component';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    RegistrationComponent,
    LoadingComponent,
    BackButtonComponent,
    GetRewardsModalComponent,
    ProfileComponent,
    ProfileFormComponent,
    LoginComponent,
    ToastComponent,
    OauthLineComponent,
    LoaderComponent,
    ImageUriPipe,
    ErrorComponent,
    InformativeComponent,
    CriteriaCardComponent,
    FooterComponent,
    SidebarComponent,
    EnrollmentsComponent,
    EnrollmentComponent,
    RegisterComponent,
    ApproveStatusComponent,
    CriterionComponent,
    OnlyNumberDirective,
    RegisterSuccessComponent,
    AbsoluteLoadingComponent,
    ApprovedCriterionComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    InputMaskModule,
    HttpClientModule,
    QRCodeModule,
    Ng2FlatpickrModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
