import { AuthService } from 'src/app/@service/auth.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { EnrollmentCategory } from '../../@type/enrollment-category.type';
import { ApiService } from 'src/app/@service/api.service';
import { Logger } from 'src/app/@utility/logger';
import { UtilityService } from '../../@service/utility.service';

const logger = new Logger('InformativeComponent');

@Component({
  selector: 'app-informative',
  templateUrl: './informative.component.html',
  styleUrls: ['./informative.component.scss'],
})
export class InformativeComponent implements OnInit {
  enrollmentCategories: EnrollmentCategory[] = [];
  loading = true;

  isOpenToRegister = true;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private authService: AuthService,
    private utilityService: UtilityService
  ) {}

  async ngOnInit() {
    this.loading = true;
    this.isOpenToRegister = await this.utilityService.isOpenToRegister();
    const response = (await this.apiService.getEnrollmentCategories()) as any;

    this.enrollmentCategories = response.results;
    this.loading = false;
  }

  scrollCriteria(el: HTMLElement) {
    el.scrollIntoView();
  }

  navigate(to: string, cateId?: string) {
    logger.debug(`determine to navigate`);
    console.log(this.authService.getSession());
    if (!this.authService.getSession()) {
      logger.debug(`session not found while trying to navigate`);
      return this.authService.lineLogin();
    }

    if (cateId) {
      this.router.navigate([to], { queryParams: { cateId } });
    } else {
      this.router.navigate([to]);
    }
  }
}
