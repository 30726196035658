<div class="app-container">
  <div class="loading-container" *ngIf="initializing">
    <app-loading></app-loading>
  </div>

  <div class="app-outlet" *ngIf="!initializing">
    <div class="d-flex justify-content-between align-items-center">
      <button (click)="toggleSidebar('in')" class="hamburger" title="menu">
        <span></span>
      </button>
      <div class="px-3">
        <img src="./../../../assets/svg/page/partner.svg" alt="partner" />
      </div>
    </div>
    <!-- hamburger = position absolute na krub -->

    <div class="screen-min-h">
      <router-outlet></router-outlet>
    </div>
    <app-sidebar
      [sidebarState]="sidebarState"
      [@slideInOut]="sidebarState"
      (onClose)="toggleSidebar('out')"></app-sidebar>
    <app-footer></app-footer>
  </div>

  <!-- <div
    class="app-outlet"
    *ngIf="!initializing"
    [@routerTransition]="getRouteAnimation()">
    <router-outlet></router-outlet>
  </div> -->

  <div
    class="w-100"
    style="position: sticky; bottom: 0.5rem"
    *ngIf="!initializing">
    <app-toast></app-toast>
  </div>
</div>
