import { ApiService } from 'src/app/@service/api.service';
import { Component, OnInit } from '@angular/core';
import { Enrollment } from '../../@type/enrollment.type';
import { Logger } from 'src/app/@utility/logger';

const logger = new Logger('EnrollmentsComponent');

@Component({
  selector: 'app-enrollments',
  templateUrl: './enrollments.component.html',
  styleUrls: ['./enrollments.component.scss'],
})
export class EnrollmentsComponent implements OnInit {
  loading = true;
  enrollments: Enrollment[] = [];

  constructor(private apiService: ApiService) {
    logger.debug(`enrollments page initialized`);
  }

  async ngOnInit() {
    logger.debug(`fetching enrollments `);
    // TODO: fetch this from backend

    const response = (await this.apiService.getEnrollments()) as any;

    this.enrollments = response.results;
    this.loading = false;
    logger.debug(`fetched ${this.enrollments.length} enrollments `);
  }
}
