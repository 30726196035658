import { Router } from '@angular/router';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from 'src/app/@service/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() sidebarState: 'in' | 'out';
  @Output() onClose: EventEmitter<void> = new EventEmitter();

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  get hasToken() {
    const tokens = this.authService.getSession();
    return !!tokens?.accessToken && !!tokens?.refreshToken;
  }

  loginWithLine() {
    this.authService.lineLogin();
  }

  logout() {
    this.handleClose();
    this.authService.logout();
  }

  navigate(to: string) {
    this.handleClose();
    this.router.navigate([to]);
  }

  handleClose() {
    this.onClose.emit();
  }
}
