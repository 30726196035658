import { Injectable } from '@angular/core';
import { LineAuthVerifyPayload } from 'src/app/@type/auth.type';
import { environment } from 'src/environments/environment';
import { UserInfoPayload } from '../@type/user.type';
import { ProfileInputType } from '../@type/registration.type';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private endpoint = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getSystemTime() {
    const url = `${this.endpoint}/system/time`;
    return await firstValueFrom(this.http.get(url));
  }

  lineVerify(lineToken: string) {
    const url = `${this.endpoint}/auth/line-verify`;
    const options = {
      headers: {
        'line-id-token': lineToken,
        'line-channel-id': environment.lineChannelId,
      },
    };
    return this.http.post<LineAuthVerifyPayload>(url, {}, options).toPromise();
  }

  getUserInfo() {
    const url = `${this.endpoint}/api/users/me`;
    return this.http.get<UserInfoPayload>(url).toPromise();
  }

  updateUserInfo(userValue: ProfileInputType) {
    const url = `${this.endpoint}/api/users/me`;
    return this.http.patch<void>(url, userValue).toPromise();
  }

  async postRegistation(payload: any) {
    const url = `${this.endpoint}/api/enrollments`;
    return await firstValueFrom(this.http.post(url, payload));
  }

  async getEnrollmentCategories() {
    const url = `${this.endpoint}/api/enrollment-categories?orderBy=sequence:asc`;
    return await firstValueFrom(this.http.get(url));
  }

  async getEnrollments() {
    const url = `${this.endpoint}/api/enrollments/me`;
    return await firstValueFrom(this.http.get(url));
  }

  async getEnrollmentById(id: string) {
    const url = `${this.endpoint}/api/enrollments/${id}`;
    return await firstValueFrom(this.http.get(url));
  }
}
