<app-loading [isOutlet]="true" *ngIf="fetching"></app-loading>

<div class="content px-3 text-center mt-4 screen-min-h" *ngIf="!fetching">
  <h4>ลงทะเบียนสมัครคัดเลือกเข้าประกวด</h4>

  <div class="wrapper-card mt-3">
    <div class="wrapper-card-inner">
      <p>ขอขอบคุณ</p>
      <p>คุณ {{ enrollment.name }}</p>
      <p>การลงทะเบียนคัดเลือกเข้าประกวดของ</p>
      <p>{{ enrollment.buffaloDetail.name }}</p>
      <p>{{ enrollment.buffaloDetail.code }}</p>
      <p class="description">
        ได้รับการลงทะเบียนเรียบร้อยแล้ว อยู่ระหว่างพิจารณา
      </p>
      <p class="description">กรุณารอการตอบกลับ</p>
    </div>

    <div class="follow-card">
      <h1>ติดตามการพิจารณา</h1>
      <div class="click-btn" (click)="navigate('/enrollment/' + enrollment.id)">
        <p>คลิก</p>
      </div>
    </div>
  </div>

  <button
    type="button"
    (click)="navigate('/register')"
    class="btn btn-register d-flex align-items-center mx-auto mt-5 justify-content-center">
    <span>ลงทะเบียนเพิ่ม</span>
  </button>

  <button
    type="button"
    (click)="navigate('/informative')"
    class="btn btn-register d-flex align-items-center mx-auto mt-3 mb-5 justify-content-center">
    <span>กลับสู่เมนูหลัก</span>
  </button>
</div>
