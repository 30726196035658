import { Component, OnInit } from '@angular/core';
// import { Location } from '@angular/common';
import { ApiService } from 'src/app/@service/api.service';
import { AuthService } from 'src/app/@service/auth.service';
import { ToastService } from 'src/app/@service/toast.service';
import { ProfileInputType } from 'src/app/@type/registration.type';
import { ToastType } from 'src/app/@type/toast.type';
import { Logger } from 'src/app/@utility/logger';

const logger = new Logger('ProfileComponent');

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  defaultProfileValue: ProfileInputType | null = null;
  submitting = false;

  constructor(
    private toastService: ToastService,
    private apiService: ApiService,
    private authService: AuthService
  ) {
    this.submitProfileValue = this.submitProfileValue.bind(this);
  }

  ngOnInit() {
    this.defaultProfileValue = {
      firstname: this.authService.userState?.firstname || '',
      lastname: this.authService.userState?.lastname || '',
      nickname: this.authService.userState?.nickname || '',
      mobilePhone: this.authService.userState?.mobilePhone || '',
    };
  }

  async submitProfileValue(profileValue: ProfileInputType) {
    try {
      this.submitting = true;
      await this.apiService.updateUserInfo(profileValue);
      await this.authService.setUserInfo();
      // this.location.back();
      this.toastService.show(
        'สำเร็จ',
        'อัพเดทข้อมูลโพนไฟล์สำเร็จ',
        3,
        ToastType.Success
      );
    } catch (error) {
      logger.error(error);
    } finally {
      this.submitting = false;
    }
  }
}
