const firebaseStorageReplaceKey = '<image_name>';

export const environment = {
  production: true,
  apiUrl: 'https://asia-southeast1-thaiswampbuffalo.cloudfunctions.net/api',
  liffId: '2001647363-rBEkGl0y',
  lineChannelId: '2001647363',
  firebaseStorage: {
    url: `https://firebasestorage.googleapis.com/v0/b/thaiswampbuffalo.appspot.com/o/${firebaseStorageReplaceKey}?alt=media`,
    replaceKey: firebaseStorageReplaceKey,
  },
  sentry: {
    enable: true,
    environment: 'production',
    dsn: 'https://2a9ebfc4436e48c898e1dc360c5bd776@sentry.rawinlab.com/21',
    tracesSampleRate: 0.1,
  },
  enableVconsole: false,
};
