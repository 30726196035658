import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import { initSentry } from './app/@utility/sentry';
import VConsole from 'vconsole';
import { initSentry } from './app/@utility/sentry';

if (environment.enableVconsole) {
  const vConsole = new VConsole();
  vConsole.hide();
}

if (environment.production) {
  enableProdMode();
}

initSentry();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
