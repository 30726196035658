<div class="content px-3 mt-4 mb-5">
  <div class="text-center">
    <h5>หลักเกณฑ์ ข้อกำหนด และเงื่อนไข การนำกระบือเข้าประกวด</h5>
    <h6>การประกวดกระบือปลักไทย ชิงถ้วยพระราชทานพระบาทสมเด็จพระเจ้าอยู่หัว</h6>
    <h6>13-14 มกราคม 2567</h6>
  </div>
  <div class="mt-4">
    <strong>1. กระบือที่ส่งเข้าประกวด</strong>
    <p>
      1.1 จะต้องมีเครื่องหมายประจำตัวสัตว์ติดอยู่ที่ตัวกระบือแบบถาวร เช่น
      ติดเบอร์หูพลาสติก การสักเบอร์หู หรือมีการฝังไมโครชิฟ
    </p>
    <p>
      1.2 มีรูปร่างลักษณะตรงตามอัตลักษณ์ ลักษณะประจำพันธุ์ของกระบือ (ควาย)
      ปลักไทย ตามการขึ้นทะเบียนพันธุ์สัตว์ ของกรมปศุสัตว์ “กระบือปลักไทย DLD BU
      01/2023”
    </p>
    <p>1.3 มีน้ำหนักตัว ในแต่ละรุ่นของการประกวด ตามเกณฑ์กำหนด ดังนี้</p>
    <table class="table table-bordered mt-3 mb-3">
      <thead>
        <tr>
          <th>ประเภท</th>
          <th>วัน เดือน ปี<br />เกิด</th>
          <th>เพศผู้<br />(ไม่เกิน กก.)</th>
          <th>เพศเมีย<br />(ไม่เกิน กก.)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            รุุ่นลูกควาย (Calf division) อายุ มากกว่า 10 เดือน ถึง ไม่เกิน 18
            เดือน
          </td>
          <td class="text-center">ระหว่างเดือน ก.ค.65 -มี.ค.66</td>
          <td class="text-center">540</td>
          <td class="text-center">490</td>
        </tr>
        <tr>
          <td>
            รุ่นลูกควาย (Calf division) อายุ มากกว่า 18 เดือน ถึง ไม่เกิน 24
            เดือน
          </td>
          <td class="text-center">ระหว่างเดือน ม.ค.65- มิ.ย.65</td>
          <td class="text-center">680</td>
          <td class="text-center">610</td>
        </tr>
        <tr>
          <td>
            รุ่นควายรุ่น (Intermediate division) ฟันแท้ 1 คู่ (อายุมากกว่า 24
            เดือน ถึง ไม่เกิน 36 เดือน)
          </td>
          <td class="text-center">ระหว่างเดือน ม.ค.64-ม.ค.65</td>
          <td class="text-center">920</td>
          <td class="text-center">810</td>
        </tr>
        <tr>
          <td>
            รุ่นควายหนุ่มสาว (Junior division) ฟันแท้ 2 คู่ (อายุมากกว่า 36
            เดือน ถึง ไม่เกิน 48 เดือน)
          </td>
          <td class="text-center">ระหว่างเดือน ม.ค.63-ม.ค.64</td>
          <td class="text-center">1,150</td>
          <td class="text-center">1,010</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="mt-4">
    <p>
      ** อายุกระบือนับถึงวันประกวด
      <br />
      *** เกณฑ์น้ำหนักพิจารณาข้อมูลจากวิจัยการเลี้ยงดูกระบือขุนตามช่วงอายุ
      ของกรมปศุสัตว์และมหาวิทยาลัยเกษตรศาสตร์ และนำมาปรับใช้ให้เหมาะสม
      เพื่อให้ใกล้เคียงของการเลี้ยงกระบือด้วยอาหารหยาบและอาหารข้นที่สมดุล
      ป้องกันไม่ให้เกิดการให้อาหารข้นที่มากเกินไป (Over feeding)
      จนทำให้เกิดการผิดปกติของระบบย่อยอาหาร เพื่อค้นหากระบือที่พันธุกรรมที่ดี
      สามารถเจริญเติบโตได้ด้วยอาหารหยาบที่ต้นทุนต่ำ
      และสามารถนำกระบือนั้นมาเป็นต้นแบบกระบือปลักไทย
      ให้เกษตรกรนำไปใช้ในการปรับปรุงพันธุ์กระบือของตนได้
    </p>
  </div>
  <div class="mt-4">
    <strong class="mt-4">2. เอกสารประกอบตัวกระบือที่ส่งเข้าประกวด</strong>
    <p>2.1 เอกสารด้านการระบุอายุกระบือ (นำมาแสดงในวันตรวจสอบหลักฐาน)</p>
    <p>
      &nbsp;&nbsp;2.1.1 ในรุ่นฟันน้ำนม ต้องมีเอกสารที่ระบุ วัน เดือน ปี เกิด
      ดังนี้ <br />
      &nbsp;&nbsp;&nbsp;&nbsp;1) บัตรประจำตัวสัตว์ของกรมปศุสัตว์<br />
      &nbsp;&nbsp;&nbsp;&nbsp;2) ใบพันธุ์ประวัติที่รับรองโดยกรมปศุสัตว์
      หรือสมาคมอนุรักษ์และพัฒนาควายไทย
      หรือสมาคมพัฒนาพันธุ์ควายไทยจังหวัดอุทัยธานี
    </p>
    <p>&nbsp;&nbsp;2.1.2 ในรุ่นฟันแท้ จะมีเอกสารตามข้อ 2.1.1 หรือไม่มีก็ได้</p>
    <p>2.2 เอกสารด้านสุขภาพกระบือ (นำมาแสดงในวันตรวจสอบหลักฐาน)</p>
    <p>
      &nbsp;&nbsp;2.2.1 มีหนังสือรับรองการตรวจโรคแท้งติดต่อ (Brucellosis)
      จากหน่วยงานของกรมปศุสัตว์
    </p>
    <p>
      &nbsp;&nbsp;2.2.2 มีใบรับรองการฉีดวัคซีน จากหน่วยงานของกรมปศุสัตว์<br />
      &nbsp;&nbsp;&nbsp;&nbsp;1) โรคปากและเท้าเปื่อย FMD
      (ต้องได้รับวัคซีนเข็มที่2 มาแล้วอย่างน้อย 21 วัน และไม่เกิน 120 วัน)<br />
      &nbsp;&nbsp;&nbsp;&nbsp;2) โรคคอบวม (ได้รับวัคซีน 1 เข็ม อย่างน้อย 21 วัน
      และไม่เกิน 1 ปี)
    </p>

    <div class="mt-4">
      <strong class="mt-4">3. การลงทะเบียนประกวดกระบือ (ล่วงหน้า)</strong>
      <p>
        3.1 มีการรับลงทะเบียนสมัครเข้าประกวดกระบือล่วงหน้า ในวันที่ 15 พฤศจิกายน
        – 15 ธันวาคม 2566 ผ่านช่องทาง <br />&nbsp;&nbsp;&nbsp;&nbsp;เว็บไซด์
        <a href="https://www.thaiswampbuffalo.com/" target="_blank"
          >www.thaiswampbuffalo.com</a
        >
        <br />&nbsp;&nbsp;&nbsp;&nbsp;ไลน์
        <a href="https://lin.ee/crnDVeF" target="_blank"
          >https://lin.ee/crnDVeF</a
        ><br />
        โดยคณะกรรมการจะตรวจสอบคุณสมบัติกระบือ หากกระบือผ่านตามหลักเกณฑ์ที่กำหนด
        จะมี SMS หรือ Line แจ้งเตือนกลับไป
      </p>
      <p>
        3.2 กระบือทุกตัวที่ผ่านตามหลักเกณฑ์แล้ว และสามารถเข้าร่วมการประกวดได้
        ในวันตรวจสอบหลักฐาน (ในวันที่ 12-13 มกราคม 2567)
        จะได้รับการเจาะเลือดเพื่อตรวจโครโมโซมยืนยันการเป็นกระบือปลัก
      </p>
    </div>

    <div class="mt-4">
      <strong class="mt-4"
        >4. การตรวจสอบเอกสารหลักฐาน และ การชั่งน้ำหนัก วัดสัดส่วน
        กระบือที่เข้าประกวด</strong
      >
      <p>
        4.1 วันที่ 12 มกราคม 2567 เริ่มตั้งแต่เวลา 13.00-20.00 น. (กระบือเผือก)
      </p>
      <p>
        4.2 วันที่ 13 มกราคม 2567 เริ่มตั้งแต่เวลา 13.00-20.00 น. (กระบือดำ)
      </p>
    </div>

    <div class="mt-4">
      <strong class="mt-4">5. ข้อกำหนดในการประกวดกระบือ</strong>
      <p>5.1 ห้ามป้อนหญ้า ป้อนอาหาร หรือห้ามกรอกอาหาร กรอกน้ำให้กระบือ</p>
      <p>
        5.2 กระบือที่เข้าประกวดทุกตัว ต้องมีการตรวจหาสารเร่งเนื้อแดงจากปัสสาวะ
      </p>
      <p>
        5.3 กระบือทุกตัวเมื่อมารายงานตัว ตรวจสอบเอกสารหลักฐานเรียบร้อยแล้ว
        ให้อยู่ในคอกพักที่จัดไว้ ห้ามนำกระบือออกนอกบริเวณคอกพักที่กำหนด
      </p>
      <p>5.4 ห้ามกระทำการใดๆ ที่ผิดกฎหมายทุกประการ</p>
      <p>หากพบจะตัดสิทธิ์ไม่ให้ร่วมประกวด หรือตัดสิทธิ์ในการได้รางวัล</p>
    </div>

    <div class="mt-4">
      <strong class="mt-4">เงื่อนไขกระบือที่ได้รับรางวัล</strong>
      <p>
        6.1 มีการมอบถ้วยรางวัลสำหรับกระบือที่ได้รับ รางวัลชนะเลิศ ลำดับที่ 1 – 3
        ทุกรุ่น จะต้องเจาะเลือดเพื่อตรวจโรคที่สำคัญทางระบบสืบพันธุ์ 10 โรค
        และจะนำข้อมูลเพื่อจัดทำเป็นหนังสือรวมพ่อพันธุ์และแม่พันธุ์กระบือปลักไทย
        และเผยแพร่ให้เกษตรกรนำไปใช้เป็นต้นแบบในการปรับปรุงพันธุ์ต่อไป
        รางวัลลำดับที่ 4-10 มอบเป็นใบประกาศนียบัตร
      </p>
      <p>
        6.2 กระบือเพศผู้ที่ได้รับรางวัลที่ 1-3 ในรุ่นฟันแท้ 1 คู่ และฟันแท้ 2
        คู่ทุกตัว เมื่อผ่านการตรวจโรคที่สำคัญทางระบบสืบพันธุ์ 10 โรคแล้ว
        เจ้าของกระบือต้องยินยอมให้นำเข้ารีตน้ำเชื้อ
        โดยศูนย์น้ำเชื้อที่ได้รับรองมาตรฐานจากกรมปศุสัตว์
        เพื่อเก็บรักษาพันธุกรรมกระบือปลักไทย
      </p>
      <p>
        6.3 กระบือที่ได้รับรางวัล Grand Champion เมื่อได้รับการประกาศผลในงานแล้ว
        จะเข้ารับพระราชทานถ้วยรางวัลต่อหน้าพระบรมฉายาลักษณ์ ที่กรมปศุสัตว์
        ภายหลังทราบผลการตรวจโรคติดต่อทางระบบสืบพันธุ์ 10 โรค
        หากตรวจพบว่าเป็นโรคตามที่กำหนด
        จะพิจารณารางวัลให้กระบือตัวที่ได้รับรางวัลถัดไปแทน
      </p>
    </div>
  </div>
</div>
