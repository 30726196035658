import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(private apiService: ApiService) {}

  async isOpenToRegister() {
    const currentTimeString = await this.apiService.getSystemTime();
    const givenDatetime = new Date(currentTimeString.toString());
    const targetDatetime = new Date('2023-12-15T23:59:59');
    return givenDatetime < targetDatetime;
  }

  sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortArrayOfObjects = (arr: any[], key: string) =>
    arr.sort((a, b) => a[key] - b[key]);

  errorFormat = (error: unknown) => {
    if (error instanceof TypeError) {
      return {
        code: undefined,
        message: error.message,
        stack: error.stack,
      };
    } else if (error instanceof HttpErrorResponse) {
      return {
        code: error.status,
        message: error?.error?.errors?.message || error?.message || '',
        stack: undefined,
      };
    } else if (typeof error === 'string') {
      return {
        code: undefined,
        message: error,
        stack: undefined,
      };
    } else {
      return {
        code: (error as any).code, // eslint-disable-line @typescript-eslint/no-explicit-any
        message: (error as any).message ?? 'unknown error instance', //eslint-disable-line @typescript-eslint/no-explicit-any
        stack: (error as any).stack, //eslint-disable-line @typescript-eslint/no-explicit-any
      };
    }
  };
}
