import { AuthService } from 'src/app/@service/auth.service';
import { Component, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { FormService } from 'src/app/@service/form.service';
import { ToastService } from 'src/app/@service/toast.service';
import provinceJson from 'src/assets/province.json';
import { Thai } from 'flatpickr/dist/l10n/th';
import { formatDate, registerLocaleData } from '@angular/common';
import localeTh from '@angular/common/locales/th';
import { Logger } from 'src/app/@utility/logger';
import { UploadFileService } from 'src/app/@service/upload-file.service';
import { ApiService } from 'src/app/@service/api.service';
import { EnrollmentCategory } from 'src/app/@type/enrollment-category.type';
import { ActivatedRoute, Router } from '@angular/router';
import { Enrollment } from 'src/app/@type/enrollment.type';
import liff from '@line/liff';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import { UtilityService } from '../../@service/utility.service';

const log = new Logger('register');

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'th' }],
})
export class RegisterComponent implements OnInit, OnDestroy {
  enrollmentCategories: EnrollmentCategory[] = [];
  registerForm: FormGroup;
  submitting = false;

  loading = true;

  provincesValue;
  districtValue;
  subDistrictValue;
  dateOptions;

  buffaloImgIdentityCardPreview = '';
  buffaloImgStraightFacePreview = '';
  buffaloImgSideViewPreview = '';
  buffaloImgRearViewPreview = '';
  buffaloImgDiseaseDocPreview = '';
  imgCertificateVaccinePreview = '';

  isOpenToRegister = true;

  constructor(
    private _builder: FormBuilder,
    public formService: FormService,
    private toastService: ToastService,
    private uploadFileService: UploadFileService,
    private authService: AuthService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private utilityService: UtilityService
  ) {
    this.provincesValue = provinceJson;

    this.registerForm = this._builder.group({
      enrollmentCategoryId: ['', Validators.required],
      gender: ['', Validators.required],
      color: ['', Validators.required],
      name: [undefined, Validators.required],
      mobilePhone: [undefined, Validators.required],
      houseNumber: [undefined, Validators.required],
      province: ['', Validators.required],
      district: ['', Validators.required],
      subDistrict: ['', Validators.required],
      postalCode: [undefined, Validators.required],
      buffaloName: [undefined, Validators.required],
      buffaloCode: [undefined, Validators.required],
      buffaloBirthday: [undefined, Validators.required],
      chestLength: [undefined, Validators.required],
      buffaloImgIdentityCard: [undefined, Validators.required],
      buffaloImgStraightFace: [undefined, Validators.required],
      buffaloImgSideView: [undefined, Validators.required],
      buffaloImgRearView: [undefined, Validators.required],
      // buffaloImgDiseaseDoc: [undefined, Validators.required],
      // imgCertificateVaccine: [undefined, Validators.required],
      diseaseAcceptance: [false, this.mustBeTrueValidator()],
      vaccineAcceptance: [false, this.mustBeTrueValidator()],
      bloodTestAcceptance: [false, this.mustBeTrueValidator()],
      semenPreservationAcceptance: [false, this.mustBeTrueValidator()],
      prohibitFoodAcceptance: [false, this.mustBeTrueValidator()],
    });

    registerLocaleData(localeTh, 'th');
  }

  get registerFormControls() {
    return this.registerForm.controls;
  }

  async ngOnInit() {
    this.isOpenToRegister = await this.utilityService.isOpenToRegister();
    this.dateOptions = {
      altInput: true,
      locale: Thai,
      altFormat: 'd/m/Y',
      onChange: (selectedDate: string) => {
        this.registerForm
          .get('buffaloBirthday')
          ?.setValue(formatDate(selectedDate, 'dd/MM/yyyy', 'th'));
      },
      disableMobile: 'true',
    };

    this.loading = true;
    const response = (await this.apiService.getEnrollmentCategories()) as any;
    this.enrollmentCategories = response.results;
    this.loading = false;

    this.route.queryParams.subscribe(params => {
      const _param = params as any;

      if (_param.cateId) {
        this.registerForm.get('enrollmentCategoryId')?.setValue(_param.cateId);
      }
    });
  }

  ngOnDestroy() {
    clearAllBodyScrollLocks();
  }

  onChangeHandle(event, id: string, list, child?: string): void {
    const input = event.target.value;
    const dependId = Number(input.split(':')[0].trim());

    if (child) {
      if (child === 'amphure') {
        this.registerForm.get('district')?.setValue('');
        this.registerForm.get('subDistrict')?.setValue('');

        const parent = this.provincesValue.find(item => item.id === dependId);
        const childs = parent ? parent[child || ''] : [];

        this.districtValue = childs;
      } else {
        this.subDistrictValue = '';
        this.registerForm.get('subDistrict')?.setValue('');

        const parent = this.districtValue.find(
          item => item.name_th === this.registerForm.get('district')?.value
        );
        const childs = parent ? parent[child || ''] : [];

        this.subDistrictValue = childs;
      }
    }
  }

  setCondition(valueName: string, value: boolean) {
    this.registerForm.get(valueName)?.setValue(value);
  }

  async onSubmit() {
    if (!this.registerForm.valid) {
      log.debug(this.registerForm.value);

      this.registerForm.markAllAsTouched();
      this.toastService.show('ข้อมูลไม่ครบถ้วน', 'กรุณาตรวจสอบข้อมูลของคุณ');

      return;
    }

    const token = this.authService.getSession();
    if (!token) {
      return this.toastService.show(
        'เกิดข้อผิดพลาด',
        'Access token not found.'
      );
    }

    try {
      this.submitting = true;
      window.scroll({
        top: 0,
        left: 0,
      });
      disableBodyScroll(document.body);

      const { value } = this.registerForm;

      const payload = {
        enrollmentCategoryId: value.enrollmentCategoryId,
        name: value.name,
        mobilePhone: value.mobilePhone,
        color: value.color,
        gender: value.gender,
        address: {
          houseNumber: value.houseNumber,
          province: value.province,
          district: value.district,
          subDistrict: value.subDistrict,
          postalCode: value.postalCode,
        },
        buffaloDetail: {
          name: value.buffaloName,
          code: value.buffaloCode,
          birthDate: value.buffaloBirthday,
          chestLength: +value.chestLength,
          imgIdentityCard: await this.uploadFileService.upload(
            this.registerForm.value.buffaloImgIdentityCard
          ),
          imgStraightFace: await this.uploadFileService.upload(
            this.registerForm.value.buffaloImgStraightFace
          ),
          imgSideView: await this.uploadFileService.upload(
            this.registerForm.value.buffaloImgSideView
          ),
          imgRearView: await this.uploadFileService.upload(
            this.registerForm.value.buffaloImgRearView
          ),
        },
        diseaseAcceptance: value.diseaseAcceptance,
        vaccineAcceptance: value.vaccineAcceptance,
        bloodTestAcceptance: value.bloodTestAcceptance,
        semenPreservationAcceptance: value.semenPreservationAcceptance,
        prohibitFoodAcceptance: value.prohibitFoodAcceptance,
      };

      const response = (await this.apiService.postRegistation(
        payload
      )) as Enrollment;

      if (response?.id) {
        this.router.navigate([`/register-success/${response.id}`]);

        liff.sendMessages([
          {
            type: 'text',
            text: `ขอขอบคุณ
            คุณ ${response.name}
            การลงทะเบียนคัดเลือกเข้าประกวดของ
            ${response.buffaloDetail.name}
            ${response.buffaloDetail.code}
            ได้รับการลงทะเบียนเรียบร้อยแล้ว อยู่ระหว่างพิจารณา
            กรุณารอการตอบกลับ`,
          },
        ]);
      }
    } catch (error) {
      this.toastService.show('เกิดข้อผิดพลาด', 'อัพโหลดข้อมูลไม่สำเร็จ');
    } finally {
      this.submitting = false;
      enableBodyScroll(document.body);
    }

    log.debug(this.registerForm.value);
  }

  onUploadImage(event: Event, valueKey: string) {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      this.registerForm.get(valueKey)?.setValue(file);

      const reader = new FileReader();

      reader.onload = e => {
        const dataURL = e?.target?.result as string;

        switch (valueKey) {
          case 'buffaloImgIdentityCard': {
            this.buffaloImgIdentityCardPreview = dataURL;
            break;
          }
          case 'buffaloImgStraightFace': {
            this.buffaloImgStraightFacePreview = dataURL;
            break;
          }
          case 'buffaloImgSideView': {
            this.buffaloImgSideViewPreview = dataURL;
            break;
          }
          case 'buffaloImgRearView': {
            this.buffaloImgRearViewPreview = dataURL;
            break;
          }
        }
      };

      reader.readAsDataURL(file);
    }

    const fileInput = document.getElementById(valueKey) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  deleteImage(valueKey: string) {
    setTimeout(() => {
      switch (valueKey) {
        case 'buffaloImgIdentityCard': {
          this.buffaloImgIdentityCardPreview = '';
          break;
        }
        case 'buffaloImgStraightFace': {
          this.buffaloImgStraightFacePreview = '';
          break;
        }
        case 'buffaloImgSideView': {
          this.buffaloImgSideViewPreview = '';
          break;
        }
        case 'buffaloImgRearView': {
          this.buffaloImgRearViewPreview = '';
          break;
        }
      }
      this.registerForm.get(valueKey)?.setValue(undefined);
    }, 10);
  }

  private mustBeTrueValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      return value === true ? null : { mustBeTrue: true };
    };
  }
}
