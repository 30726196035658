<app-loading *ngIf="loading" [isOutlet]="true"></app-loading>
<app-absolute-loading *ngIf="submitting"></app-absolute-loading>
<ng-container *ngIf="isOpenToRegister">
  <div *ngIf="!loading" class="wrapper-register-page py-4 px-3">
    <h2 class="mb-3">ลงทะเบียนสมัครคัดเลือกเข้าประกวด</h2>
    <form [formGroup]="registerForm">
      <div class="mb-3">
        <select class="form-select" formControlName="enrollmentCategoryId">
          <option value="">เลือกรุ่น</option>
          <option *ngFor="let item of enrollmentCategories" [ngValue]="item.id">
            {{ item.name }} {{ item.detail }}
          </option>
        </select>
        <ng-container
          *ngIf="
            formService.isControlInvalid(registerForm, 'enrollmentCategoryId')
          ">
          <p
            *ngIf="
              registerFormControls?.['enrollmentCategoryId']?.errors?.[
                'required'
              ]
            "
            class="form-validation-message">
            กรุณาระบุรุ่น
          </p>
        </ng-container>
      </div>

      <div class="mb-3">
        <label class="mb-2 d-block">เพศ</label>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            formControlName="gender"
            id="male"
            name="gender"
            type="radio"
            value="male" />
          <label class="form-check-label" for="male">เพศผู้</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            formControlName="gender"
            id="female"
            name="gender"
            type="radio"
            value="female" />
          <label class="form-check-label" for="female">เพศเมีย</label>
        </div>

        <ng-container
          *ngIf="formService.isControlInvalid(registerForm, 'gender')">
          <p
            *ngIf="registerFormControls?.['gender']?.errors?.['required']"
            class="form-validation-message">
            กรุณาระบุเพศ
          </p>
        </ng-container>
      </div>

      <div class="mb-3">
        <label class="mb-2 d-block">สี</label>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            formControlName="color"
            id="albino"
            name="color"
            type="radio"
            value="albino" />
          <label class="form-check-label" for="albino">เผือก</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            formControlName="color"
            id="black"
            name="color"
            type="radio"
            value="black" />
          <label class="form-check-label" for="black">ดำ</label>
        </div>

        <ng-container
          *ngIf="formService.isControlInvalid(registerForm, 'color')">
          <p
            *ngIf="registerFormControls?.['color']?.errors?.['required']"
            class="form-validation-message">
            กรุณาระบุสี
          </p>
        </ng-container>
      </div>

      <div class="mb-3">
        <input
          class="form-control"
          formControlName="name"
          name="Fullname"
          placeholder="ชื่อ-นามสกุลผู้สมัคร"
          type="text" />
        <ng-container
          *ngIf="formService.isControlInvalid(registerForm, 'name')">
          <p
            *ngIf="registerFormControls?.['name']?.errors?.['required']"
            class="form-validation-message">
            กรุณากรอกชื่อ-นามสกุลผู้สมัคร
          </p>
        </ng-container>
      </div>
      <div class="mb-3">
        <input
          appOnlyNumber
          class="form-control"
          formControlName="mobilePhone"
          name="phoneNumber"
          placeholder="เบอร์ติดต่อ"
          type="text" />
        <ng-container
          *ngIf="formService.isControlInvalid(registerForm, 'mobilePhone')">
          <p
            *ngIf="registerFormControls?.['mobilePhone']?.errors?.['required']"
            class="form-validation-message">
            กรุณากรอกเบอร์ติดต่อ
          </p>
        </ng-container>
      </div>
      <label class="mb-2 d-block">ที่อยู่</label>
      <div class="mb-3">
        <input
          appOnlyNumber="houseNumber"
          class="form-control"
          formControlName="houseNumber"
          name="homeNumber"
          placeholder="บ้านเลขที่"
          type="text" />
        <ng-container
          *ngIf="formService.isControlInvalid(registerForm, 'houseNumber')">
          <p
            *ngIf="registerFormControls?.['houseNumber']?.errors?.['required']"
            class="form-validation-message">
            กรุณากรอกบ้านเลขที่
          </p>
        </ng-container>
      </div>
      <div class="mb-3">
        <select
          (change)="
            onChangeHandle($event, 'province_id', provincesValue, 'amphure')
          "
          class="form-select"
          formControlName="province">
          <option value="">จังหวัด</option>
          <option *ngFor="let item of provincesValue" [ngValue]="item.name_th">
            <!-- {{ item.name_th }} - {{ item.name_en }} -->
            {{ item.name_th }}
          </option>
        </select>
        <ng-container
          *ngIf="formService.isControlInvalid(registerForm, 'province')">
          <p
            *ngIf="registerFormControls?.['province']?.errors?.['required']"
            class="form-validation-message">
            กรุณาเลือกจังหวัด
          </p>
        </ng-container>
      </div>
      <div class="mb-3">
        <select
          (change)="
            onChangeHandle($event, 'amphure_id', districtValue, 'tambon')
          "
          class="form-select"
          formControlName="district">
          <option value="">อำเภอ</option>
          <option *ngFor="let item of districtValue" [ngValue]="item.name_th">
            <!-- {{ item.name_th }} - {{ item.name_en }} -->
            {{ item.name_th }}
          </option>
        </select>
        <ng-container
          *ngIf="formService.isControlInvalid(registerForm, 'district')">
          <p
            *ngIf="registerFormControls?.['district']?.errors?.['required']"
            class="form-validation-message">
            กรุณาเลือกอำเภอ
          </p>
        </ng-container>
      </div>
      <div class="mb-3">
        <select class="form-select" formControlName="subDistrict">
          <option value="">ตำบล</option>
          <option
            *ngFor="let item of subDistrictValue"
            [ngValue]="item.name_th">
            <!-- {{ item.name_th }} - {{ item.name_en }} -->
            {{ item.name_th }}
          </option>
        </select>
        <ng-container
          *ngIf="formService.isControlInvalid(registerForm, 'subDistrict')">
          <p
            *ngIf="registerFormControls?.['subDistrict']?.errors?.['required']"
            class="form-validation-message">
            กรุณาเลือกตำบล
          </p>
        </ng-container>
      </div>
      <div class="mb-3">
        <input
          appOnlyNumber
          class="form-control"
          formControlName="postalCode"
          name="zipcode"
          placeholder="รหัสไปรษณีย์"
          type="text" />
        <ng-container
          *ngIf="formService.isControlInvalid(registerForm, 'postalCode')">
          <p
            *ngIf="registerFormControls?.['postalCode']?.errors?.['required']"
            class="form-validation-message">
            กรุณากรอกรหัสไปรษณีย์
          </p>
        </ng-container>
      </div>
      <label class="mb-2 d-block">ข้อมูลกระบือ</label>
      <div class="mb-3">
        <input
          class="form-control"
          formControlName="buffaloName"
          name="buffaloName"
          placeholder="ชื่อกระบือ"
          type="text" />
        <ng-container
          *ngIf="formService.isControlInvalid(registerForm, 'buffaloName')">
          <p
            *ngIf="registerFormControls?.['buffaloName']?.errors?.['required']"
            class="form-validation-message">
            กรุณากรอกชื่อกระบือ
          </p>
        </ng-container>
      </div>
      <div class="mb-3">
        <input
          class="form-control"
          formControlName="buffaloCode"
          name="IdCardBuff"
          placeholder="เลขประจำตัวสัตว์"
          type="text" />
        <ng-container
          *ngIf="formService.isControlInvalid(registerForm, 'buffaloCode')">
          <p
            *ngIf="registerFormControls?.['buffaloCode']?.errors?.['required']"
            class="form-validation-message">
            เลขประจำตัวสัตว์
          </p>
        </ng-container>
      </div>
      <div class="mb-3">
        <input
          appOnlyNumber
          class="form-control"
          formControlName="chestLength"
          name="zipcode"
          placeholder="ความยาวรอบอก (เซนติเมตร)"
          type="text" />
        <ng-container
          *ngIf="formService.isControlInvalid(registerForm, 'chestLength')">
          <p
            *ngIf="registerFormControls?.['chestLength']?.errors?.['required']"
            class="form-validation-message">
            กรุณากรอกความยาวรอบอก
          </p>
        </ng-container>
      </div>
      <div class="mb-3">
        <ng2-flatpickr
          [addClass]="'form-control'"
          [config]="dateOptions"
          [placeholder]="'วัน/เดือน/ปี เกิดกระบือ'"></ng2-flatpickr>
        <ng-container
          *ngIf="formService.isControlInvalid(registerForm, 'buffaloBirthday')">
          <p
            *ngIf="
              registerFormControls?.['buffaloBirthday']?.errors?.['required']
            "
            class="form-validation-message">
            กรุณากรอกวัน/เดือน/ปี เกิดกระบือ
          </p>
        </ng-container>
      </div>
      <div class="mb-3">
        <div class="w-100 form-control d-block position-relative">
          <label class="w-100 h-100 d-flex align-items-center">
            <span>บัตรประจำตัวสัตว์</span>
            <input
              (change)="onUploadImage($event, 'buffaloImgIdentityCard')"
              [disabled]="registerForm.get('buffaloImgIdentityCard')?.value"
              accept="image/*"
              class="form-control"
              hidden
              id="buffaloImgIdentityCard"
              name="CardBuff"
              placeholder="บัตรประจำตัวสัตว์"
              type="file" />
            <span
              *ngIf="!registerForm.get('buffaloImgIdentityCard')?.value"
              class="upload-file-icon">
              <svg
                class="bi bi-plus-circle-fill"
                fill="currentColor"
                height="30"
                viewBox="0 0 16 16"
                width="30"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
              </svg>
            </span>
            <span
              (click)="deleteImage('buffaloImgIdentityCard')"
              *ngIf="registerForm.get('buffaloImgIdentityCard')?.value"
              class="upload-file-icon">
              <svg
                fill="#ff0000"
                height="32"
                stroke="#ff0000"
                version="1.1"
                viewBox="0 0 30 32"
                width="32"
                xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <title>cancel</title>
                  <path
                    d="M16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM21.961 12.209c0.244-0.244 0.244-0.641 0-0.885l-1.328-1.327c-0.244-0.244-0.641-0.244-0.885 0l-3.761 3.761-3.761-3.761c-0.244-0.244-0.641-0.244-0.885 0l-1.328 1.327c-0.244 0.244-0.244 0.641 0 0.885l3.762 3.762-3.762 3.76c-0.244 0.244-0.244 0.641 0 0.885l1.328 1.328c0.244 0.244 0.641 0.244 0.885 0l3.761-3.762 3.761 3.762c0.244 0.244 0.641 0.244 0.885 0l1.328-1.328c0.244-0.244 0.244-0.641 0-0.885l-3.762-3.76 3.762-3.762z"></path>
                </g>
              </svg>
            </span>
          </label>
          <div
            *ngIf="registerForm.get('buffaloImgIdentityCard')?.value"
            class="w-100 d-flex justify-content-center align-items-center p-2 mt-2">
            <img
              [src]="buffaloImgIdentityCardPreview"
              alt=""
              class="rounded"
              style="max-height: 200px" />
          </div>
        </div>

        <ng-container
          *ngIf="
            formService.isControlInvalid(registerForm, 'buffaloImgIdentityCard')
          ">
          <p
            *ngIf="
              registerFormControls?.['buffaloImgIdentityCard']?.errors?.[
                'required'
              ]
            "
            class="form-validation-message">
            กรุณาอัพโหลดรูปบัตรประจำตัวสัตว์
          </p>
        </ng-container>
      </div>
      <div class="mb-3">
        <div class="w-100 form-control d-block position-relative">
          <label class="w-100 h-100 d-flex align-items-center">
            <span>ภาพถ่ายหน้าตรง</span>
            <input
              (change)="onUploadImage($event, 'buffaloImgStraightFace')"
              [disabled]="registerForm.get('buffaloImgStraightFace')?.value"
              accept="image/*"
              class="form-control"
              hidden
              id="buffaloImgStraightFace"
              name="frontImageBuff"
              placeholder="ภาพถ่ายหน้าตรง"
              type="file" />
            <span
              *ngIf="!registerForm.get('buffaloImgStraightFace')?.value"
              class="upload-file-icon">
              <svg
                class="bi bi-plus-circle-fill"
                fill="currentColor"
                height="30"
                viewBox="0 0 16 16"
                width="30"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
              </svg>
            </span>
            <span
              (click)="deleteImage('buffaloImgStraightFace')"
              *ngIf="registerForm.get('buffaloImgStraightFace')?.value"
              class="upload-file-icon">
              <svg
                fill="#ff0000"
                height="32"
                stroke="#ff0000"
                version="1.1"
                viewBox="0 0 30 32"
                width="32"
                xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <title>cancel</title>
                  <path
                    d="M16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM21.961 12.209c0.244-0.244 0.244-0.641 0-0.885l-1.328-1.327c-0.244-0.244-0.641-0.244-0.885 0l-3.761 3.761-3.761-3.761c-0.244-0.244-0.641-0.244-0.885 0l-1.328 1.327c-0.244 0.244-0.244 0.641 0 0.885l3.762 3.762-3.762 3.76c-0.244 0.244-0.244 0.641 0 0.885l1.328 1.328c0.244 0.244 0.641 0.244 0.885 0l3.761-3.762 3.761 3.762c0.244 0.244 0.641 0.244 0.885 0l1.328-1.328c0.244-0.244 0.244-0.641 0-0.885l-3.762-3.76 3.762-3.762z"></path>
                </g>
              </svg>
            </span>
          </label>
          <div
            *ngIf="registerForm.get('buffaloImgStraightFace')?.value"
            class="w-100 d-flex justify-content-center align-items-center p-2 mt-2">
            <img
              [src]="buffaloImgStraightFacePreview"
              alt=""
              class="rounded"
              style="max-height: 200px" />
          </div>
        </div>

        <ng-container
          *ngIf="
            formService.isControlInvalid(registerForm, 'buffaloImgStraightFace')
          ">
          <p
            *ngIf="
              registerFormControls?.['buffaloImgStraightFace']?.errors?.[
                'required'
              ]
            "
            class="form-validation-message">
            กรุณาอัพโหลดรูปถ่ายหน้าตรงของสัตว์
          </p>
        </ng-container>
      </div>
      <div class="mb-3">
        <div class="w-100 form-control d-block position-relative">
          <label class="w-100 h-100 d-flex align-items-center">
            <span>ภาพถ่ายด้านข้าง</span>
            <input
              (change)="onUploadImage($event, 'buffaloImgSideView')"
              [disabled]="registerForm.get('buffaloImgSideView')?.value"
              accept="image/*"
              class="form-control"
              hidden
              id="buffaloImgSideView"
              name="frontImageBuff"
              placeholder="ภาพถ่ายด้านข้าง"
              type="file" />
            <span
              *ngIf="!registerForm.get('buffaloImgSideView')?.value"
              class="upload-file-icon">
              <svg
                class="bi bi-plus-circle-fill"
                fill="currentColor"
                height="30"
                viewBox="0 0 16 16"
                width="30"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
              </svg>
            </span>
            <span
              (click)="deleteImage('buffaloImgSideView')"
              *ngIf="registerForm.get('buffaloImgSideView')?.value"
              class="upload-file-icon">
              <svg
                fill="#ff0000"
                height="32"
                stroke="#ff0000"
                version="1.1"
                viewBox="0 0 30 32"
                width="32"
                xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <title>cancel</title>
                  <path
                    d="M16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM21.961 12.209c0.244-0.244 0.244-0.641 0-0.885l-1.328-1.327c-0.244-0.244-0.641-0.244-0.885 0l-3.761 3.761-3.761-3.761c-0.244-0.244-0.641-0.244-0.885 0l-1.328 1.327c-0.244 0.244-0.244 0.641 0 0.885l3.762 3.762-3.762 3.76c-0.244 0.244-0.244 0.641 0 0.885l1.328 1.328c0.244 0.244 0.641 0.244 0.885 0l3.761-3.762 3.761 3.762c0.244 0.244 0.641 0.244 0.885 0l1.328-1.328c0.244-0.244 0.244-0.641 0-0.885l-3.762-3.76 3.762-3.762z"></path>
                </g>
              </svg>
            </span>
          </label>
          <div
            *ngIf="registerForm.get('buffaloImgSideView')?.value"
            class="w-100 d-flex justify-content-center align-items-center p-2 mt-2">
            <img
              [src]="buffaloImgSideViewPreview"
              alt=""
              class="rounded"
              style="max-height: 200px" />
          </div>
        </div>
        <ng-container
          *ngIf="
            formService.isControlInvalid(registerForm, 'buffaloImgSideView')
          ">
          <p
            *ngIf="
              registerFormControls?.['buffaloImgSideView']?.errors?.['required']
            "
            class="form-validation-message">
            กรุณาอัพโหลดรูปถ่ายด้านข้างของสัตว์
          </p>
        </ng-container>
      </div>
      <div class="mb-3">
        <div class="w-100 form-control d-block position-relative">
          <label class="w-100 h-100 d-flex align-items-center">
            <span>ภาพถ่ายด้านท้าย</span>
            <input
              (change)="onUploadImage($event, 'buffaloImgRearView')"
              [disabled]="registerForm.get('buffaloImgRearView')?.value"
              accept="image/*"
              class="form-control"
              hidden
              id="buffaloImgRearView"
              name="frontImageBuff"
              placeholder="ภาพถ่ายด้านท้าย"
              type="file" />
            <span
              *ngIf="!registerForm.get('buffaloImgRearView')?.value"
              class="upload-file-icon">
              <svg
                class="bi bi-plus-circle-fill"
                fill="currentColor"
                height="30"
                viewBox="0 0 16 16"
                width="30"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
              </svg>
            </span>
            <span
              (click)="deleteImage('buffaloImgRearView')"
              *ngIf="registerForm.get('buffaloImgRearView')?.value"
              class="upload-file-icon">
              <svg
                fill="#ff0000"
                height="32"
                stroke="#ff0000"
                version="1.1"
                viewBox="0 0 30 32"
                width="32"
                xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <title>cancel</title>
                  <path
                    d="M16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM21.961 12.209c0.244-0.244 0.244-0.641 0-0.885l-1.328-1.327c-0.244-0.244-0.641-0.244-0.885 0l-3.761 3.761-3.761-3.761c-0.244-0.244-0.641-0.244-0.885 0l-1.328 1.327c-0.244 0.244-0.244 0.641 0 0.885l3.762 3.762-3.762 3.76c-0.244 0.244-0.244 0.641 0 0.885l1.328 1.328c0.244 0.244 0.641 0.244 0.885 0l3.761-3.762 3.761 3.762c0.244 0.244 0.641 0.244 0.885 0l1.328-1.328c0.244-0.244 0.244-0.641 0-0.885l-3.762-3.76 3.762-3.762z"></path>
                </g>
              </svg>
            </span>
          </label>
          <div
            *ngIf="registerForm.get('buffaloImgRearView')?.value"
            class="w-100 d-flex justify-content-center align-items-center p-2 mt-2">
            <img
              [src]="buffaloImgRearViewPreview"
              alt=""
              class="rounded"
              style="max-height: 200px" />
          </div>
        </div>
        <ng-container
          *ngIf="
            formService.isControlInvalid(registerForm, 'buffaloImgRearView')
          ">
          <p
            *ngIf="
              registerFormControls?.['buffaloImgRearView']?.errors?.['required']
            "
            class="form-validation-message">
            กรุณาอัพโหลดรูปถ่ายด้านท้ายของสัตว์
          </p>
        </ng-container>
      </div>
      <!-- <div class="mb-3">
        <div class="w-100 form-control d-block position-relative">
          <label class="w-100 h-100 d-flex align-items-center">
            <span>เอกสารการตรวจโรคแท้งติดต่อและวัคซีน</span>
            <input
              hidden
              id="buffaloImgDiseaseDoc"
              type="file"
              accept="image/*"
              name="frontImageBuff"
              (change)="onUploadImage($event, 'buffaloImgDiseaseDoc')"
              [disabled]="registerForm.get('buffaloImgDiseaseDoc')?.value"
              class="form-control"
              placeholder="เอกสารการตรวจโรคแท้งติดต่อและวัคซีน" />
            <span
              *ngIf="!registerForm.get('buffaloImgDiseaseDoc')?.value"
              class="upload-file-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                class="bi bi-plus-circle-fill"
                viewBox="0 0 16 16">
                <path
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
              </svg>
            </span>
            <span
              *ngIf="registerForm.get('buffaloImgDiseaseDoc')?.value"
              (click)="deleteImage('buffaloImgDiseaseDoc')"
              class="upload-file-icon">
              <svg
                fill="#ff0000"
                width="32"
                height="32"
                viewBox="0 0 30 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#ff0000">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <title>cancel</title>
                  <path
                    d="M16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM21.961 12.209c0.244-0.244 0.244-0.641 0-0.885l-1.328-1.327c-0.244-0.244-0.641-0.244-0.885 0l-3.761 3.761-3.761-3.761c-0.244-0.244-0.641-0.244-0.885 0l-1.328 1.327c-0.244 0.244-0.244 0.641 0 0.885l3.762 3.762-3.762 3.76c-0.244 0.244-0.244 0.641 0 0.885l1.328 1.328c0.244 0.244 0.641 0.244 0.885 0l3.761-3.762 3.761 3.762c0.244 0.244 0.641 0.244 0.885 0l1.328-1.328c0.244-0.244 0.244-0.641 0-0.885l-3.762-3.76 3.762-3.762z"></path>
                </g>
              </svg>
            </span>
          </label>
          <div
            *ngIf="registerForm.get('buffaloImgDiseaseDoc')?.value"
            class="w-100 d-flex justify-content-center align-items-center p-2 mt-2">
            <img
              class="rounded"
              style="max-height: 200px"
              [src]="buffaloImgDiseaseDocPreview"
              alt="" />
          </div>
        </div>
        <ng-container
          *ngIf="
            formService.isControlInvalid(registerForm, 'buffaloImgDiseaseDoc')
          ">
          <p
            *ngIf="
              registerFormControls?.['buffaloImgDiseaseDoc']?.errors?.['required']
            "
            class="form-validation-message">
            กรุณาอัพโหลดรูปถ่ายเอกสารการตรวจโรคแท้งติดต่อและวัคซีน
          </p>
        </ng-container>
      </div>
      <div class="mb-3">
        <div class="w-100 form-control d-block position-relative">
          <label class="w-100 h-100 d-flex align-items-center">
            <span>ใบรับรองการฉีดวัคซีน</span>
            <input
              hidden
              id="imgCertificateVaccine"
              type="file"
              accept="image/*"
              name="CardBuff"
              (change)="onUploadImage($event, 'imgCertificateVaccine')"
              [disabled]="registerForm.get('imgCertificateVaccine')?.value"
              class="form-control"
              placeholder="ใบรับรองการฉีดวัคซีน" />
            <span
              *ngIf="!registerForm.get('imgCertificateVaccine')?.value"
              class="upload-file-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                class="bi bi-plus-circle-fill"
                viewBox="0 0 16 16">
                <path
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
              </svg>
            </span>
            <span
              *ngIf="registerForm.get('imgCertificateVaccine')?.value"
              (click)="deleteImage('imgCertificateVaccine')"
              class="upload-file-icon">
              <svg
                fill="#ff0000"
                width="32"
                height="32"
                viewBox="0 0 30 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#ff0000">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <title>cancel</title>
                  <path
                    d="M16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM21.961 12.209c0.244-0.244 0.244-0.641 0-0.885l-1.328-1.327c-0.244-0.244-0.641-0.244-0.885 0l-3.761 3.761-3.761-3.761c-0.244-0.244-0.641-0.244-0.885 0l-1.328 1.327c-0.244 0.244-0.244 0.641 0 0.885l3.762 3.762-3.762 3.76c-0.244 0.244-0.244 0.641 0 0.885l1.328 1.328c0.244 0.244 0.641 0.244 0.885 0l3.761-3.762 3.761 3.762c0.244 0.244 0.641 0.244 0.885 0l1.328-1.328c0.244-0.244 0.244-0.641 0-0.885l-3.762-3.76 3.762-3.762z"></path>
                </g>
              </svg>
            </span>
          </label>
          <div
            *ngIf="registerForm.get('imgCertificateVaccine')?.value"
            class="w-100 d-flex justify-content-center align-items-center p-2 mt-2">
            <img
              class="rounded"
              style="max-height: 200px"
              [src]="buffaloImgIdentityCardPreview"
              alt="" />
          </div>
        </div>

        <ng-container
          *ngIf="
            formService.isControlInvalid(registerForm, 'imgCertificateVaccine')
          ">
          <p
            *ngIf="
              registerFormControls?.['imgCertificateVaccine']?.errors?.[
                'required'
              ]
            "
            class="form-validation-message">
            กรุณาอัพโหลดใบรับรองการฉีดวัคซีน
          </p>
        </ng-container>
      </div> -->
      <div class="wrapper-condition mt-4 mb-3">
        <p class="mb-3">เงื่อนไขและข้อตกลง</p>
        <ol>
          <li class="mb-3">
            กระบือที่ผ่านการคัดเลือกเข้าประกวด จะต้องทำการตรวจโรคแท้งติดต่อ
            โดยสามารถติดต่อกับปศุสัตว์ในพื้นที่
            เพื่อนำเอกสารการตรวจโรคมายืนตอนลงทะเบียนที่สนามประกวด
            (ไม่มีค่าใช้จ่าย)
            <ng-container
              *ngIf="
                formService.isControlInvalid(registerForm, 'diseaseAcceptance')
              ">
              <p
                *ngIf="registerFormControls?.['diseaseAcceptance']?.errors"
                class="form-validation-message">
                กรุณายอมรับข้อตกลง
              </p>
            </ng-container>
            <div class="d-flex align-items-center justify-content-around gap-3">
              <button
                (click)="setCondition('diseaseAcceptance', true)"
                [ngClass]="{
                  'btn-accept': registerForm.get('diseaseAcceptance')?.value
                }"
                class="btn btn-condition"
                type="button">
                ยินยอม
              </button>
              <button
                (click)="setCondition('diseaseAcceptance', false)"
                [ngClass]="{
                  'btn-decline': !registerForm.get('diseaseAcceptance')?.value
                }"
                class="btn btn-condition"
                type="button">
                ไม่ยินยอม
              </button>
            </div>
          </li>

          <li class="mb-3">
            กระบือที่ผ่านการคัดเลือกเข้าประกวด จะต้องขอเอกสารรับรองการฉีดวัคซีน
            โดยสามารถติดต่อกับปศุสัตว์ในพื้นที่
            เพื่อนำเอกสารมายืนตอนลงทะเบียนที่สนามประกวด (ไม่มีค่าใช้จ่าย)
            <ng-container
              *ngIf="
                formService.isControlInvalid(registerForm, 'vaccineAcceptance')
              ">
              <p
                *ngIf="registerFormControls?.['vaccineAcceptance']?.errors"
                class="form-validation-message">
                กรุณายอมรับข้อตกลง
              </p>
            </ng-container>
            <div class="d-flex align-items-center justify-content-around gap-3">
              <button
                (click)="setCondition('vaccineAcceptance', true)"
                [ngClass]="{
                  'btn-accept': registerForm.get('vaccineAcceptance')?.value
                }"
                class="btn btn-condition"
                type="button">
                ยินยอม
              </button>
              <button
                (click)="setCondition('vaccineAcceptance', false)"
                [ngClass]="{
                  'btn-decline': !registerForm.get('vaccineAcceptance')?.value
                }"
                class="btn btn-condition"
                type="button">
                ไม่ยินยอม
              </button>
            </div>
          </li>

          <li class="mb-3">
            กระบือที่ผ่านการพิจารณา จะต้องเจาะเลือดเพื่อตรวจโครโมโซม
            ยืนยันการเป็นกระบือปลักไทย ณ สนามประกวด (ไม่มีค่าใช้จ่าย)
            <ng-container
              *ngIf="
                formService.isControlInvalid(
                  registerForm,
                  'bloodTestAcceptance'
                )
              ">
              <p
                *ngIf="registerFormControls?.['bloodTestAcceptance']?.errors"
                class="form-validation-message">
                กรุณายอมรับข้อตกลง
              </p>
            </ng-container>
            <div class="d-flex align-items-center justify-content-around gap-3">
              <button
                (click)="setCondition('bloodTestAcceptance', true)"
                [ngClass]="{
                  'btn-accept': registerForm.get('bloodTestAcceptance')?.value
                }"
                class="btn btn-condition"
                type="button">
                ยินยอม
              </button>
              <button
                (click)="setCondition('bloodTestAcceptance', false)"
                [ngClass]="{
                  'btn-decline': !registerForm.get('bloodTestAcceptance')?.value
                }"
                class="btn btn-condition"
                type="button">
                ไม่ยินยอม
              </button>
            </div>
          </li>
          <li class="mb-3">
            กรณีกระบือของท่านได้รับรางวัล ท่านยินดีนำ
            กระบือเข้าศูนย์รีดมาตรฐานของกรมปศุสัตว์ เพื่อทำการรีดน้ำเชื้อ
            (ไม่มีค่าใช้จ่าย)
            <ng-container
              *ngIf="
                formService.isControlInvalid(
                  registerForm,
                  'semenPreservationAcceptance'
                )
              ">
              <p
                *ngIf="
                  registerFormControls?.['semenPreservationAcceptance']?.errors
                "
                class="form-validation-message">
                กรุณายอมรับข้อตกลง
              </p>
            </ng-container>
            <div class="d-flex align-items-center justify-content-around gap-3">
              <button
                (click)="setCondition('semenPreservationAcceptance', true)"
                [ngClass]="{
                  'btn-accept': registerForm.get('semenPreservationAcceptance')
                    ?.value
                }"
                class="btn btn-condition"
                type="button">
                ยินยอม
              </button>
              <button
                (click)="setCondition('semenPreservationAcceptance', false)"
                [ngClass]="{
                  'btn-decline': !registerForm.get(
                    'semenPreservationAcceptance'
                  )?.value
                }"
                class="btn btn-condition"
                type="button">
                ไม่ยินยอม
              </button>
            </div>
          </li>
          <li class="mb-3">
            ห้ามป้อนหญ้า ป้อนอาหาร ห้ามกรอกอาหาร กรอกน้ำให้กระบือ
            <ng-container
              *ngIf="
                formService.isControlInvalid(
                  registerForm,
                  'prohibitFoodAcceptance'
                )
              ">
              <p
                *ngIf="registerFormControls?.['prohibitFoodAcceptance']?.errors"
                class="form-validation-message">
                กรุณายอมรับข้อตกลง
              </p>
            </ng-container>
            <div class="d-flex align-items-center justify-content-around gap-3">
              <button
                (click)="setCondition('prohibitFoodAcceptance', true)"
                [ngClass]="{
                  'btn-accept': registerForm.get('prohibitFoodAcceptance')
                    ?.value
                }"
                class="btn btn-condition"
                type="button">
                ยินยอม
              </button>
              <button
                (click)="setCondition('prohibitFoodAcceptance', false)"
                [ngClass]="{
                  'btn-decline': !registerForm.get('prohibitFoodAcceptance')
                    ?.value
                }"
                class="btn btn-condition"
                type="button">
                ไม่ยินยอม
              </button>
            </div>
          </li>
        </ol>
      </div>
      <button
        (click)="onSubmit()"
        [disabled]="submitting"
        class="btn btn-register d-flex align-items-center mx-auto mt-5 mb-4 justify-content-center"
        type="button">
        <span>ยืนยันการลงทะเบียน</span>
      </button>
    </form>
  </div>
</ng-container>
<ng-container *ngIf="!isOpenToRegister">
  <div class="text-center">
    <h2 class="mt-5">ระบบปิดรับการลงทะเบียนแล้ว</h2>
  </div>
</ng-container>
