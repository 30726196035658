<div class="page-not-found-container">
  <div class="title-intro">Page not found!</div>
  <div class="wrapper-btn-to-home">
    <button
      [routerLink]="'/informative'"
      type="button"
      class="btn btn-warning btn-lg btn-block btn-main w-100">
      กลับหน้าหลัก
    </button>
  </div>
</div>
