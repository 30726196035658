import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/@service/toast.service';
import { ToastModel } from 'src/app/@type/toast.type';
import { toastAnimation } from 'src/app/@utility/toast-animation';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [toastAnimation],
})
export class ToastComponent implements OnDestroy {
  dismissEnabled = false;
  public toastModel: ToastModel;

  private $subscriptions: Subscription;

  constructor(private _toastService: ToastService) {
    this.$subscriptions = this._toastService.$toastState.subscribe(
      (toastModel: ToastModel) => {
        this.toastModel = toastModel;
      }
    );
  }

  public close() {
    this.toastModel.visible = false;
  }

  ngOnDestroy() {
    this.$subscriptions.unsubscribe();
  }
}
