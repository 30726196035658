import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import {
  ProfileFormType,
  ProfileInputType,
} from 'src/app/@type/registration.type';
import { isEmpty } from 'lodash';

import { FormService } from 'src/app/@service/form.service';
import { createMask } from '@ngneat/input-mask';
import { Logger } from 'src/app/@utility/logger';

const log = new Logger('ProfileFormComponent');
@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
})
export class ProfileFormComponent implements OnInit {
  @Input() submitCallback: (formValue: ProfileInputType) => Promise<void>;
  @Input() defaultValue: ProfileInputType | null | undefined;
  @Input() submitting: boolean;

  mobilePhoneMask = createMask('099 999 9999');
  submitText: 'ลงทะเบียน' | 'บันทึก' = 'ลงทะเบียน';

  profileForm: FormGroup<ProfileFormType> = this.fb.nonNullable.group({
    firstname: ['', Validators.required],
    lastname: ['', Validators.required],
    nickname: ['', Validators.required],
    mobilePhone: ['', [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    public formService: FormService
  ) {}

  ngOnInit() {
    this.setFormDefaultValue();
  }

  setFormDefaultValue() {
    if (!isEmpty(this.defaultValue)) {
      this.profileForm.patchValue(this.defaultValue);
      this.submitText = 'บันทึก';
    }
  }

  get firstnameControl() {
    return this.profileForm.get('firstname');
  }

  get lastnameControl() {
    return this.profileForm.get('lastname');
  }

  get nicknameControl() {
    return this.profileForm.get('nickname');
  }

  get mobilePhoneControl() {
    return this.profileForm.get('mobilePhone');
  }

  handleSubmit() {
    try {
      if (!this.profileForm.valid) {
        this.profileForm.markAllAsTouched();
        return;
      }

      const value = this.profileForm.value as ProfileInputType;
      const formValue: ProfileInputType = {
        ...value,
        mobilePhone: this.formService.removePhoneNumberMask(value.mobilePhone),
      };

      if (!!this.submitCallback) {
        this.submitCallback(formValue);
      }
    } catch (error) {
      log.error(error);
      throw error;
    }
  }
}
