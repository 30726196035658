import { Injectable } from '@angular/core';
import liff from '@line/liff';
import { Logger } from '../@utility/logger';
import { Router } from '@angular/router';
import { LocalStorageKey } from '../@type/common.type';
import { ApiService } from './api.service';
import { UserState } from '../@type/user.type';
import { isEmpty, pick } from 'lodash';
import { ToastService } from './toast.service';
import { UtilityService } from './utility.service';

const logger = new Logger('AuthService');

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userState: UserState | null = null;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private toastService: ToastService,
    private utilityService: UtilityService
  ) {}

  lineLogin() {
    try {
      // this.lineLogout();
      console.log(`line login`);
      liff.ready.then(async () => {
        console.log(`liff ready`);
        // if (!liff.isLoggedIn()) {
        //   const currentUrl = window.location.href;
        //   logger.debug(`Liff destinationUrl = ${currentUrl}`);
        //   liff.login({ redirectUri: currentUrl });
        // }
        console.log(`force login`);
        liff.login();
      });
    } catch (error) {
      logger.error(error);
      console.log(error);
      this.toastService.show(
        'เกิดข้อผิดพลาด',
        this.utilityService.errorFormat(error).message
      );
    }
  }

  lineLogout() {
    liff.ready.then(() => {
      liff.isLoggedIn() && liff.logout();
    });
  }

  logout() {
    this.lineLogout();
    this.setSession();
    this.router.navigate(['/informative']);
  }

  setDestinationPath(path?: string) {
    if (!!path) {
      localStorage.setItem(LocalStorageKey.DestinationPath, path);
    } else {
      localStorage.removeItem(LocalStorageKey.DestinationPath);
    }
  }

  getDestinationPath() {
    return localStorage.getItem(LocalStorageKey.DestinationPath);
  }

  setSession(accessToken?: string, refreshToken?: string) {
    if (!!accessToken && !!refreshToken) {
      localStorage.setItem(LocalStorageKey.AccessToken, accessToken);
      localStorage.setItem(LocalStorageKey.RefreshToken, refreshToken);
    } else {
      localStorage.removeItem(LocalStorageKey.AccessToken);
      localStorage.removeItem(LocalStorageKey.RefreshToken);
    }
  }

  getSession() {
    const accessToken = localStorage.getItem(LocalStorageKey.AccessToken);
    const refreshToken = localStorage.getItem(LocalStorageKey.RefreshToken);
    if (!!accessToken && !!refreshToken) {
      return { accessToken, refreshToken };
    }
    return null;
  }

  async setUserInfo() {
    try {
      const userPayload = await this.apiService.getUserInfo();
      if (isEmpty(userPayload)) {
        throw new Error('User payload is empty');
      }
      const userState: UserState = pick(userPayload, [
        'withLine',
        'firstname',
        'lastname',
        'nickname',
        'mobilePhone',
        'id',
        'isInitProfile',
      ]);
      this.userState = userState;
      console.log('user state ' + this.userState.id);
    } catch (error) {
      logger.error(error);
      this.toastService.show(
        'เกิดข้อผิดพลาด',
        this.utilityService.errorFormat(error).message
      );

      this.logout();
    }
  }
}
