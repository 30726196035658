import { Component, OnInit } from '@angular/core';
import { Enrollment } from '../../@type/enrollment.type';
import { ActivatedRoute } from '@angular/router';
// import { mockEnrollments } from '../../@mock/enrollments.mock';
import { ToastService } from '../../@service/toast.service';
import { NotFoundError } from 'rxjs';
import { Logger } from 'src/app/@utility/logger';
import { UtilityService } from '../../@service/utility.service';
import { ApiService } from 'src/app/@service/api.service';

const logger = new Logger('EnrollmentComponent');

@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.scss'],
})
export class EnrollmentComponent implements OnInit {
  fetching = false;
  error = false;
  enrollmentId: string;
  enrollment: Enrollment;

  constructor(
    private route: ActivatedRoute,
    private toastService: ToastService,
    private utilityService: UtilityService,
    private apiService: ApiService
  ) {
    const enrollmentId = this.route.snapshot.paramMap.get('enrollmentId');
    !!enrollmentId && (this.enrollmentId = enrollmentId);
  }

  async ngOnInit() {
    this.fetching = true;
    await this.mount();
    this.fetching = false;
  }

  async mount() {
    if (this.enrollmentId) {
      try {
        this.error = false;
        await this.fetchEnrollment();
      } catch (error) {
        this.error = true;
        logger.error(error);
        this.toastService.show(
          'เกิดข้อผิดพลาด',
          this.utilityService.errorFormat(error).message
        );
      }
    }
  }

  async fetchEnrollment() {
    const response = (await this.apiService.getEnrollmentById(
      this.enrollmentId
    )) as any;

    // check if existed
    if (response) {
      this.enrollment = response;
    } else {
      throw new NotFoundError(`Enrollment not found`);
    }
  }
}
