<div class="wrapper-card">
  <div class="wrapper-card-inner">
    <h2>{{ title }}</h2>
    <p *ngIf="!!detail">{{ detail }}</p>
    <p *ngIf="!!description" class="description">{{ description }}</p>
  </div>

  <div class="follow-card">
    <h1>จำนวนการสมัคร {{ stat }} ตัว</h1>
  </div>
</div>
