import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-criteria-card',
  templateUrl: './criteria-card.component.html',
  styleUrls: ['./criteria-card.component.scss'],
})
export class CriteriaCardComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() detail: string;
  @Input() stat: number;
}
