<div class="profile-container page-wrapper">
  <div class="page-inner px-0 profile-form">
    <div class="intro-wrapper">
      <h2>โปรไฟล์ของฉัน</h2>
      <p>อัพเดทข้อมูลส่วนตัวของคุณ</p>
    </div>
    <app-profile-form
      [defaultValue]="defaultProfileValue"
      [submitCallback]="submitProfileValue"
      [submitting]="submitting">
    </app-profile-form>
  </div>
</div>
