<app-loading [isOutlet]="true" *ngIf="loading"></app-loading>

<div class="content px-3 text-center mt-4 mb-5" *ngIf="!loading">
  <h4>การลงทะเบียนสมัครของคุณ</h4>

  <ng-container *ngIf="enrollments.length === 0">
    <h5 class="text-muted mt-5 mb-3">คุณยังไม่ได้ทำการลงทะเบียนสมัคร</h5>
    <a [routerLink]="['/register']" class="btn btn-xs btn-warning"
      >ลงทะเบียนสมัคร</a
    >
  </ng-container>
  <ng-container *ngIf="enrollments.length > 0">
    <div *ngFor="let enrollment of enrollments" class="wrapper-card mt-3">
      <div
        [routerLink]="['/enrollment/' + enrollment.id]"
        class="wrapper-card-inner">
        <h2>{{ enrollment.buffaloDetail.name }}</h2>
        <p *ngIf="enrollment.enrollmentCategory">
          {{ enrollment?.enrollmentCategory?.name }}
        </p>
      </div>
    </div>
  </ng-container>
</div>
