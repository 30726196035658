import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  contacts = [
    {
      logoSrc: './../../../assets/png/Thaiswampbuffalo-logo.jpg',
      url: 'https://www.facebook.com/thaiswampbuffaloofficial',
    },
    {
      logoSrc: './../../../assets/png/LineOA-logo.png',
      url: 'https://lin.ee/crnDVeF',
    },
    {
      logoSrc: './../../../assets/png/moac-logo.png',
      url: 'https://www.moac.go.th/site-home',
    },
    {
      logoSrc: './../../../assets/png/dld-logo.png',
      url: 'https://dld.go.th/th/index.php/th/',
    },
  ];
}
