import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  isControlInvalid(form: FormGroup, controlName: string): boolean {
    const control = form.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  controlHasError(
    form: FormGroup,
    validation: string,
    controlName: string
  ): boolean {
    const control = form.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched);
  }

  addPhoneNumberMask(tel: string) {
    if (!tel) return '';
    if (tel.length !== 10) return '';
    const pattern = '### ### ####';
    let i = 0;
    const v = tel.toString();
    return pattern.replace(/#/g, () => v[i++]);
  }

  removePhoneNumberMask(tel: string) {
    if (!tel) return '';
    if (tel.length !== 12) return tel;
    return tel.split(' ').join('');
  }
}
