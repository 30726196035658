import {
  animate,
  group,
  keyframes,
  query,
  style,
  animation,
  AnimationReferenceMetadata,
  transition,
  useAnimation,
  trigger,
} from '@angular/animations';

const sharedStyles = {
  position: 'fixed',
  overflow: 'hidden',
  backfaceVisibility: 'hidden',
  transformStyle: 'preserve-3d',
};

const moveFromRight: AnimationReferenceMetadata = animation(
  [
    query(':enter, :leave', style(sharedStyles), { optional: true }),
    group([
      query(
        ':enter',
        [
          animate(
            '{{enterTiming}}s {{enterDelay}}s ease',
            keyframes([
              style({
                transform: 'translateX(100%)',
                offset: 0,
                'z-index': '9999',
              }),
              style({ transform: 'translateX(0%)', offset: 1 }),
            ])
          ),
        ],
        { optional: true }
      ),
      query(
        ':leave',
        [
          animate(
            '{{leaveTiming}}s {{leaveDelay}}s ease',
            keyframes([
              style({ transform: 'translateX(0%)', offset: 0 }),
              style({
                transform: 'translateX(-100%)',
                opacity: '0',
                offset: 1,
              }),
            ])
          ),
        ],
        { optional: true }
      ),
    ]),
  ],
  {
    params: {
      enterTiming: '.6',
      leaveTiming: '0.6',
      enterDelay: '0',
      leaveDelay: '0',
    },
  }
);

const moveFromLeft: AnimationReferenceMetadata = animation(
  [
    query(':enter, :leave', style(sharedStyles), { optional: true }),
    group([
      query(
        ':enter',
        [
          animate(
            '{{enterTiming}}s {{enterDelay}}s ease',
            keyframes([
              style({
                transform: 'translateX(-100%)',
                offset: 0,
                'z-index': '9999',
              }),
              style({ transform: 'translateX(0%)', offset: 1 }),
            ])
          ),
        ],
        { optional: true }
      ),

      query(
        ':leave',
        [
          animate(
            '{{leaveTiming}}s {{leaveDelay}}s ease',
            keyframes([
              style({ transform: 'translateX(0%)', offset: 0 }),
              style({ transform: 'translateX(100%)', opacity: '0', offset: 1 }),
            ])
          ),
        ],
        { optional: true }
      ),
    ]),
  ],
  {
    params: {
      enterTiming: '.6',
      leaveTiming: '0.6',
      enterDelay: '0',
      leaveDelay: '0',
    },
  }
);

export const routerTransition = trigger('routerTransition', [
  transition(':enter', useAnimation(moveFromLeft), {
    params: {
      enterTiming: '0.35',
      leaveTiming: '0.35',
    },
  }),
  transition(':leave', useAnimation(moveFromRight), {
    params: {
      enterTiming: '0.35',
      leaveTiming: '0',
    },
  }),
  transition(':increment', useAnimation(moveFromRight), {
    params: {
      enterTiming: '0.35',
      leaveTiming: '0.35',
    },
  }),
  transition(':decrement', useAnimation(moveFromLeft), {
    params: {
      enterTiming: '0.35',
      leaveTiming: '0.35',
    },
  }),
]);
