<div class="registration-container page-wrapper">
  <div class="page-inner px-0 register-form">
    <div class="intro-wrapper">
      <h2>ลงทะเบียนใช้งานระบบ</h2>
      <p>กรอกข้อมูลลงทะเบียนเพื่อเข้าใช้งานระบบ</p>
    </div>
    <app-profile-form
      [submitCallback]="submitProfileValue"
      [submitting]="submitting"></app-profile-form>
  </div>
</div>
