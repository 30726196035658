import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// guard
import { JwtGuard } from './@guard/jwt.guard';
// page
import { PageNotFoundComponent } from './@page/page-not-found/page-not-found.component';
import { RegistrationComponent } from './@page/registration/registration.component';
import { ProfileComponent } from './@page/profile/profile.component';
import { OauthLineComponent } from './@page/oauth-line/oauth-line.component';
import { InformativeComponent } from './@page/informative/informative.component';
import { RegisterComponent } from './@page/register/register.component';
import { EnrollmentsComponent } from './@page/enrollments/enrollments.component';
import { EnrollmentComponent } from './@page/enrollment/enrollment.component';
import { CriterionComponent } from './@page/criterion/criterion.component';
import { RegisterSuccessComponent } from './@page/register-success/register-success.component';
import { ApprovedCriterionComponent } from './@page/approved-criterion/approved-criterion.component';

const routes: Routes = [
  {
    path: '',
    component: OauthLineComponent,
  },
  {
    path: 'informative',
    component: InformativeComponent,
  },
  {
    path: 'approved-criterion',
    component: ApprovedCriterionComponent,
  },
  {
    path: 'criterion',
    component: CriterionComponent,
  },
  {
    path: 'enrollments',
    component: EnrollmentsComponent,
    canActivate: [JwtGuard],
  },
  {
    path: 'enrollment/:enrollmentId',
    component: EnrollmentComponent,
    canActivate: [JwtGuard],
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [JwtGuard],
  },
  {
    path: 'register-success/:enrollmentId',
    component: RegisterSuccessComponent,
    canActivate: [JwtGuard],
  },
  {
    path: 'registration',
    component: RegistrationComponent,
    canActivate: [JwtGuard],
    // data: { animateState: 1 },
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [JwtGuard],
    // data: { animateState: 3 },
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
