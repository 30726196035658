import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/@service/api.service';
import { AuthService } from 'src/app/@service/auth.service';
import { ProfileInputType } from 'src/app/@type/registration.type';
import { Logger } from 'src/app/@utility/logger';

const logger = new Logger('RegistrationComponent');
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent {
  submitting = false;
  loading = true;

  constructor(
    private router: Router,
    private authService: AuthService,
    private apiService: ApiService
  ) {
    this.submitProfileValue = this.submitProfileValue.bind(this);
    if (this.authService.userState?.isInitProfile) {
      this.router.navigate(['/informative']);
    }
  }

  async submitProfileValue(profileValue: ProfileInputType) {
    try {
      this.submitting = true;
      await this.apiService.updateUserInfo(profileValue);
      await this.authService.setUserInfo();
      const path = this.authService.getDestinationPath();
      this.authService.setDestinationPath();
      this.router.navigate([!!path ? path : '/informative']);
    } catch (error) {
      logger.error(error);
    } finally {
      this.submitting = false;
    }
  }
}
